import {
  DateIsoWeek,
  DateIsoWeekDay,
  MonthDate,
  PeriodHoursMinutes,
  WEEK_DAYS_ISO,
  WeekDate,
} from '@mabadive/app-common-model';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
// import isoWeek from 'dayjs/plugin/isoWeek';

// NOTE: dayjs reference: https://github.com/iamkun/dayjs/blob/dev/docs/en/API-reference.md
// NODE: dayjs i18n reference: https://github.com/iamkun/dayjs/blob/dev/docs/en/I18n.md

export const HASURA_DATE_ONLY_FORMAT = 'YYYY-MM-DDT00:00:00+00:00';
export const HASURA_DATE_ONLY_JSON_FORMAT = 'YYYY-MM-DDT00:00:00.000';
export const HASURA_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss+00:00';
export const HASURA_DATE_TIME_WITH_MS_FORMAT =
  'YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00';

export const dateService = {
  init,
  formatUTC,
  getWeekOfYear,
  parseUTCFromDDMMYYYY,
  formatPeriod,
  formatLocal,
  getUTCDateWithoutTime,
  getUTCDateWithoutTimeFromLocalTime,
  getUTCDateSetTime,
  getWeekDates,
  getISODay,
  isTodayUTC,
  isSameDayUTC,
  isBefore,
  isPastUTC,
  isPastWeekUTC,
  isPastWeekUTCFromLocal,
  isFutureDayUTC,
  getAge,
  getAgeInMonths,
  getAgeInDays,
  add,
  isBirthday,
  getUTCWeekDayIso,
  formatIsoDayIso,
  setUTCWeekDayIso,
  getFirstDayOfWeek,
  buildDateWeeksAgoFromBeginOfWeek,
  parseFromIsoWeek,
  convertMaterailUiPickerLocalDateToUtc,
  haveCommonDaysUTC,
  isDateWithinRange,
  getDiffInMonths,
  getDiffInDays,
  getDiffInHours,
  getDiffInMinutes,
  getMonthBoundsUTC,
  getYearBoundsUTC,
  getFiscalYearBounds,
  getFirstWeekDay,
  getMonthDates,
  isDateWithinPeriodHoursMinutes,
};
function getMonthBoundsUTC(date: Date): [Date, Date] {
  date = new Date(date);
  const beginDate = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1),
  );
  const endDate = add(add(beginDate, 1, 'month'), -1, 'day');
  return [beginDate, endDate];
}
function getYearBoundsUTC(date: Date): [Date, Date] {
  date = new Date(date);
  const beginDate = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const endDate = add(add(beginDate, 1, 'year'), -1, 'day');
  return [beginDate, endDate];
}
function getFiscalYearBounds(
  date: Date,
  { startMonth }: { startMonth: number },
): [Date, Date] {
  const currentYear = date.getUTCFullYear();
  const fiscalStartYear =
    date.getUTCMonth() >= startMonth ? currentYear : currentYear - 1;

  // Calculate the start date of the fiscal year
  const fiscalStartDate = new Date(Date.UTC(fiscalStartYear, startMonth, 1));

  // Calculate the end date of the fiscal year
  const fiscalEndDate = new Date(fiscalStartDate);
  fiscalEndDate.setUTCFullYear(fiscalEndDate.getUTCFullYear() + 1);

  // Subtract one day to get the actual end date of the fiscal year
  fiscalEndDate.setUTCDate(fiscalEndDate.getUTCDate() - 1);

  return [fiscalStartDate, fiscalEndDate];
}

function init() {
  dayjs.extend(utc, {});
  // dayjs.extend(isoWeek)
  dayjs.extend(weekYear);
  dayjs.extend(weekOfYear);
  dayjs.extend(advancedFormat);
  dayjs.locale('fr'); // default locale
}

function parseFromIsoWeek({
  isoYear,
  isoWeek,
  isoWeekDay,
}: {
  isoYear: number;
  isoWeek: DateIsoWeek;
  isoWeekDay: DateIsoWeekDay;
}) {
  // if (!initialized.done) {
  //   init();
  //   initialized.done = true;
  // }
  const buggyMonth = Math.abs(isoWeek / 4);
  return new Date(
    Date.UTC(isoYear, buggyMonth, isoWeek - (7 * buggyMonth) / 4),
  );
  // const date = dayjs(new Date(Date.UTC(isoYear, 0, 1)));
  // if (!isoWeek) {

  // }
  // return (date as any).isoWeek(isoWeek).isoWeekDay(isoWeekDay).toDate();
}

function convertMaterailUiPickerLocalDateToUtc(date: Date) {
  if (date) {
    // be sure ui picket won't mutate the original object
    date = new Date(date);
    // valid date
    if (
      date.getUTCHours() !== 0 &&
      date.getUTCMinutes() === 0 &&
      date.getUTCSeconds() === 0 &&
      date.getUTCMilliseconds() === 0
    ) {
      // probably come from keyboard, and is localized: https://github.com/mui-org/material-ui-pickers/issues/1348
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    } else {
      // datepicker, nothing to do
      date.setUTCHours(0);
      date.setUTCMinutes(0);
      date.setUTCSeconds(0);
      date.setUTCMilliseconds(0);
    }
  }
  return date;
}
function getUTCWeekDayIso(date: Date): DateIsoWeekDay {
  return (((date.getUTCDay() + 6) % 7) + 1) as DateIsoWeekDay;
}
function getISODay(date: Date): DateIsoWeekDay {
  return getUTCWeekDayIso(date);
}
function getFirstWeekDay({
  refDate,
  firstIsoWeekDay,
}: {
  refDate: Date;
  firstIsoWeekDay: DateIsoWeekDay;
}) {
  const isoDay = getUTCWeekDayIso(refDate);
  const diffWithFirstDay = (7 + isoDay - firstIsoWeekDay) % 7;
  const firstDay = add(refDate, -diffWithFirstDay, 'day');
  return firstDay;
}
function formatIsoDayIso(
  isoDay: DateIsoWeekDay,
  {
    format,
  }: {
    format: 'dd' | 'ddd' | 'dddd';
  } = {
    format: 'dd',
  },
): string {
  const dayDate = dateService.setUTCWeekDayIso(new Date(), isoDay);
  const str = formatUTC(dayDate, format);
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}
function setUTCWeekDayIso(inputDate: Date, isoDay: DateIsoWeekDay): Date {
  const date = new Date(inputDate);

  var currentDay = date.getDay();
  const daytoset = ((isoDay + 7 - 1) % 7) + 1;
  var distance = daytoset - currentDay;
  date.setDate(date.getDate() + distance);
  return date;
}

function add(
  date: Date,
  value: number,
  unit: 'day' | 'week' | 'month' | 'year' | 'hour' | 'minute',
): Date {
  if (unit === 'day') {
    return addDays(date, value);
  }
  if (unit === 'month') {
    return addMonths(date, value);
  }
  // fixme: ne fonctionne pas correctement avec UTC sur le TitleDateNavigator
  return dayjs(date).add(value, unit).toDate();
}

function addDays(originDate: Date, daysToAdd: number): Date {
  // Note: attention aux changement d'heure été/hivers pendant la période!
  const finalDate = new Date(originDate);
  finalDate.setUTCDate(finalDate.getUTCDate() + daysToAdd);
  return finalDate;
}
function addMonths(originDate: Date, monthsToAdd: number): Date {
  const finalDate = new Date(originDate);
  finalDate.setUTCMonth(finalDate.getUTCMonth() + monthsToAdd);
  return finalDate;
}

function getAge(birthday: Date, reference = new Date()) {
  // birthday is a date
  return dayjs(reference).diff(birthday, 'year');
}
function getAgeInMonths(birthday: Date, reference = new Date()) {
  // birthday is a date
  return dayjs(reference).diff(birthday, 'month');
}
function getAgeInDays(birthday: Date, reference = new Date()) {
  // birthday is a date
  return dayjs(reference).diff(birthday, 'day');
}

function formatUTC(date: Date, format: string) {
  if (!date || !date.getTime || isNaN(date.getTime())) {
    return '';
  }

  return dayjs(date).utc().format(format);
}
function getWeekOfYear(date: Date): number {
  if (!date || !date.getTime || isNaN(date.getTime())) {
    return undefined;
  }
  // note: le 1er janvier n'est pas toujours la semaine 1, mais parfois la 52: https://en.wikipedia.org/wiki/ISO_week_date#First_week
  return dayjs(date).utc().week();
}

function parseUTCFromDDMMYYYY(
  str: string,
  {
    minDate,
    maxDate,
  }: {
    minDate?: Date;
    maxDate?: Date;
  } = {},
): {
  isValid: boolean;
  date?: Date;
} {
  // BUG: dayjs ne tient pas compte du format lors du parsing !!! => donc on utilise Date.parse au format ISO
  const format = 'DD/MM/YYYY';
  if (!str || str.length === 0) {
    return { isValid: true };
  }
  const separator = '/';

  // var regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
  const regex =
    /^([0-9]|[0-2][0-9]|(3)[0-1])(\/)(([0-9]|(0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  if (!regex.test(str)) {
    if (str.length === 8 && /^\d+$/.test(str)) {
      // only numbers
      const d = Number.parseInt(str.substring(0, 2));
      const m = Number.parseInt(str.substring(2, 4));
      const y = Number.parseInt(str.substring(4, 8));
      if (y < 1000 || y > 9999 || m < 0 || m > 11 || d < 1 || d > 31) {
        // invalid date
        return { isValid: false };
      }
      const date = new Date(Date.UTC(y, m - 1, d));
      if (date instanceof Date && !isNaN(date as unknown as number)) {
        if (minDate && minDate.getTime() > date.getTime()) {
          return { isValid: false };
        }
        if (maxDate && maxDate.getTime() < date.getTime()) {
          return { isValid: false };
        }
        return { date, isValid: true };
      }
    }
    return { isValid: false };
  }
  const chunks = str.split(separator);
  if (chunks.length !== 3) {
    return { isValid: false };
  }
  const strISO = `${chunks[2]}-${chunks[1]}-${chunks[0]}`;
  const date = new Date(Date.parse(strISO));

  const y = Number.parseInt(chunks[2]);
  const m = Number.parseInt(chunks[1]) - 1;
  const d = Number.parseInt(chunks[0]);
  if (y < 1000 || y > 9999 || m < 0 || m > 11 || d < 1 || d > 31) {
    // invalid date
    return { isValid: false };
  }

  if (date instanceof Date && !isNaN(date as unknown as number)) {
    if (minDate && minDate.getTime() > date.getTime()) {
      return { isValid: false };
    }
    if (maxDate && maxDate.getTime() < date.getTime()) {
      return { isValid: false };
    }
    return { date, isValid: true };
  }

  return { isValid: false };
}

function isBirthday(birthdate: Date, reference = new Date()) {
  return (
    birthdate.getUTCMonth() === reference.getUTCMonth() &&
    birthdate.getUTCDate() === reference.getUTCDate()
  );
}

function formatLocal(date: Date, format: string) {
  if (!date) {
    return '';
  }
  // return dayjs(date).utc().local().format(format);
  return dayjs(date)?.format(format);
}
// FIXME passer tout ça en UTC, et controller les heures

function getUTCDateWithoutTime(date?: Date) {
  if (!date) {
    date = new Date();
  }
  const timestamp = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    0,
    0,
    0,
    0,
  );

  const utcString = new Date(timestamp).toUTCString();

  const utcDate = new Date(utcString);

  return utcDate;
}
function getUTCDateWithoutTimeFromLocalTime(date?: Date) {
  if (!date) {
    date = new Date();
  }
  // on prend la date et heure locale (ex 27/11/2022 à 04h, 14h00 ou 22h en Guadeloupe)
  // et on la transforme en date UTC sans les heures, donc 27/11/2022 UTC
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0,
  );
  const utcString = new Date(timestamp).toUTCString();

  const utcDate = new Date(utcString);

  return utcDate;
}

function getUTCDateSetTime(
  date?: Date,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
) {
  if (!date) {
    date = new Date();
  }
  const timestamp = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    hours,
    minutes,
    seconds,
    milliseconds,
  );

  const utcString = new Date(timestamp).toUTCString();

  const utcDate = new Date(utcString);

  return utcDate;
}

function isTodayUTC(date: Date, nowAsUtc?: Date): boolean {
  if (!nowAsUtc) {
    const now = new Date();
    nowAsUtc = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()),
    );
  }
  return isSameDayUTC(nowAsUtc, date);
}

function isSameDayUTC(date1: Date, date2: Date): boolean {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const isToday =
    date2.getUTCDate() === date1.getUTCDate() &&
    date2.getUTCMonth() === date1.getUTCMonth() &&
    date2.getUTCFullYear() === date1.getUTCFullYear();
  return isToday;
}

function isFutureDayUTC(date: Date, refDate = new Date()): boolean {
  if (date && !date?.getTime) {
    date = new Date(date);
  }
  const refDateWithoutTime = getUTCDateSetTime(refDate);
  const dateWithoutTime = getUTCDateSetTime(date);
  return refDateWithoutTime.getTime() < dateWithoutTime.getTime();
}

function isPastUTC(date: Date): boolean {
  return isBefore(date);
}
function isPastWeekUTC(date: Date): boolean {
  const dateWitoutTime = getUTCDateSetTime(date);
  const firstDayOfCurrentWeek = setUTCWeekDayIso(
    getUTCDateSetTime(new Date()),
    1,
  );

  return isBefore(dateWitoutTime, firstDayOfCurrentWeek);
}
function isPastWeekUTCFromLocal(date: Date): boolean {
  const dateWitoutTime = getUTCDateSetTime(date);

  // Note: fonctionne bien avec la timezone de Tahiti
  const firstDayOfCurrentWeek = getFirstDayOfWeek(new Date(), { locale: 'fr' });

  return isBefore(dateWitoutTime, firstDayOfCurrentWeek);
}

function isBefore(date: Date, refDate = new Date()): boolean {
  if (date && !date?.getTime) {
    date = new Date(date);
  }
  return dayjs(date).isBefore(refDate);
}
// unchanged: import statements and utility functions

export type DateServiceLocaleMode = 'local' | 'utc';
function getMonthDates(
  date: Date,
  {
    mode,
  }: {
    mode: DateServiceLocaleMode;
  },
): MonthDate[] {
  if (!date) {
    date = new Date();
  }

  const year = mode === 'utc' ? date.getUTCFullYear() : date.getFullYear();
  const monthIndex = mode === 'utc' ? date.getUTCMonth() : date.getMonth();
  const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();

  const monthDates: MonthDate[] = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate =
      mode === 'utc'
        ? new Date(Date.UTC(year, monthIndex, day))
        : new Date(year, monthIndex, day);
    monthDates.push({
      date: currentDate,
      isoWeekDay:
        mode === 'utc' ? getISODay(currentDate) : getISODay(currentDate),
      monthIndex,
    });
  }

  return monthDates;
}

// unchanged: interfaces and type definitions

function getWeekDates(date?: Date): WeekDate[] {
  if (!date) {
    date = new Date();
  }

  const firstDayOfWeek = getFirstDayOfWeek(date, { locale: 'fr' });

  const firstDayOfWeekWithoutTime = getUTCDateWithoutTime(firstDayOfWeek);

  const res = WEEK_DAYS_ISO.reduce(
    (acc, isoWeekDay) => {
      acc.dates.push({
        date: acc.nextDay.toDate(),
        isoWeekDay,
      });
      return {
        dates: acc.dates,
        nextDay: acc.nextDay.add(1, 'day'),
      };
    },
    {
      dates: [] as WeekDate[],
      nextDay: dayjs(firstDayOfWeekWithoutTime) as Dayjs,
    },
  );

  return res.dates;
}

function getFirstDayOfWeek(date: Date, { locale }: { locale: string }) {
  return dayjs(date).utc().locale(locale).startOf('week').toDate();
}

function buildDateWeeksAgoFromBeginOfWeek({
  base,
  weeksAgo,
}: {
  base: Date;
  weeksAgo: number;
}) {
  const firstDayOfWeek = getFirstDayOfWeek(base, { locale: 'fr' });
  return new Date(firstDayOfWeek.getTime() - 3600 * 24 * 7 * weeksAgo * 1000);
}

function formatPeriod(
  d1: Date,
  d2: Date,
  { skipYearIfThisYear }: { skipYearIfThisYear: boolean } = {
    skipYearIfThisYear: false,
  },
) {
  if (!d1) {
    d1 = new Date();
  }
  if (!d2) {
    d2 = new Date();
  }
  if (d1.getUTCFullYear() === d2.getUTCFullYear()) {
    if (
      d1.getUTCMonth() === d2.getUTCMonth() &&
      d1.getUTCDate() === d2.getUTCDate()
    ) {
      // only one day
      if (
        skipYearIfThisYear &&
        d1.getUTCFullYear() === new Date().getUTCFullYear()
      ) {
        // this year
        return dateService.formatUTC(d1, 'DD/MM');
      }
      return dateService.formatUTC(d1, 'DD/MM/YYYY');
    }
    if (
      skipYearIfThisYear &&
      d2.getUTCFullYear() === new Date().getUTCFullYear()
    ) {
      // same year, this year
      return `${dateService.formatUTC(d1, 'DD/MM')}-${dateService.formatUTC(
        d2,
        'DD/MM',
      )}`;
    }
  }
  return `${dateService.formatUTC(d1, 'DD/MM/YYYY')}-${dateService.formatUTC(
    d2,
    'DD/MM/YYYY',
  )}`;
}
function isValidRange(dStart: Date | number, dEnd: Date | number): boolean {
  if (new Date(dStart).getTime() > new Date(dEnd).getTime()) {
    return false;
  }
  return true;
}
function isWithinRange(d: Date, dStart: Date, dEnd: Date): boolean {
  if (!isValidRange(dStart, dEnd)) {
    return false;
  }
  return (
    new Date(d).getTime() >= new Date(dStart).getTime() &&
    new Date(d).getTime() <= new Date(dEnd).getTime()
  );
}
function haveCommonDaysUTC(
  min1: Date,
  max1: Date,
  min2: Date,
  max2: Date,
): boolean {
  const min1Fix = getUTCDateSetTime(min1);
  const max1Fix = getUTCDateSetTime(max1);
  const min2Fix = getUTCDateSetTime(min2);
  const max2Fix = getUTCDateSetTime(max2);
  return (
    isWithinRange(min1Fix, min2Fix, max2Fix) ||
    isWithinRange(max1Fix, min2Fix, max2Fix) ||
    isWithinRange(min2Fix, min1Fix, max1Fix) ||
    isWithinRange(max2Fix, min1Fix, max1Fix)
  );
}

function isDateWithinPeriodHoursMinutes(
  date: Date,
  period: PeriodHoursMinutes,
): boolean {
  if (!date || !period) {
    return false;
  }
  const dateHours = date.getHours();
  const dateMinutes = date.getMinutes();

  const startMinutes = period.start.hours * 60 + period.start.minutes;
  const endMinutes = period.end.hours * 60 + period.end.minutes;
  const dateTotalMinutes = dateHours * 60 + dateMinutes;

  if (startMinutes <= endMinutes) {
    // Period does not cross midnight
    return dateTotalMinutes >= startMinutes && dateTotalMinutes <= endMinutes;
  } else {
    // Period crosses midnight
    return dateTotalMinutes >= startMinutes || dateTotalMinutes <= endMinutes;
  }
}
function isDateWithinRange(
  date: Date,
  {
    minDate,
    maxDateExclusive,
  }: {
    minDate: Date;
    maxDateExclusive: Date;
  },
) {
  if (!date.getTime) {
    date = new Date(date);
  }
  if (!date || !minDate || !maxDateExclusive) {
    return false;
  }
  if (!minDate.getTime) {
    minDate = new Date(minDate);
  }
  if (!maxDateExclusive.getTime) {
    maxDateExclusive = new Date(maxDateExclusive);
  }
  return (
    date.getTime() < maxDateExclusive.getTime() &&
    date.getTime() >= minDate.getTime()
  );
}
function getDiffInMonths(d1: Date, d2: Date): number {
  const durationInMs = Math.abs(d1.getTime() - d2.getTime());
  return durationInMs / ((1000 * 3600 * 24 * 365) / 12);
}

function getDiffInDays(d1: Date, d2: Date): number {
  const durationInMs = Math.abs(d1.getTime() - d2.getTime());
  return durationInMs / (1000 * 3600 * 24);
}
function getDiffInHours(d1: Date, d2: Date): number {
  const durationInMs = Math.abs(d1.getTime() - d2.getTime());
  return durationInMs / (1000 * 3600);
}

function getDiffInMinutes(d1: Date, d2: Date): number {
  const durationInMs = Math.abs(d1.getTime() - d2.getTime());
  return durationInMs / (1000 * 60);
}
