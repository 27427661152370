import {
  ExportStaffMemberLevelFormat,
  SessionDiveGroupSupervision,
} from '@mabadive/app-common-model';
import { nameFormatter } from '../../business/string/nameFormatter.service';

export const diveSupervisionFormatter = {
  formatSupervisionLabel,
  formatSupervisionLevel,
  formatSupervisionLabelOrAptitude,
  formatSupervisionAptitude,
};

function formatSupervisionLabel({
  diveSupervision,
}: {
  diveSupervision: Pick<
    SessionDiveGroupSupervision,
    'instructor' | 'diveGuide' | 'isAutonomous'
  >;
}) {
  const { instructor, diveGuide, isAutonomous } = diveSupervision;

  if (instructor) {
    return nameFormatter.formatFullName(instructor.profile);
  } else if (diveGuide) {
    return nameFormatter.formatFullName(diveGuide.diver);
  }
}
function formatSupervisionLabelOrAptitude({
  diveSupervision,
}: {
  diveSupervision: Pick<
    SessionDiveGroupSupervision,
    'instructor' | 'diveGuide' | 'isAutonomous'
  >;
}) {
  const { instructor, diveGuide, isAutonomous } = diveSupervision;

  if (instructor) {
    return nameFormatter.formatFullName(instructor.profile);
  } else if (diveGuide) {
    return nameFormatter.formatFullName(diveGuide.diver);
  } else if (isAutonomous) {
    return 'AUTONOMES';
  }
}

function formatSupervisionAptitude({
  diveSupervision,
}: {
  diveSupervision: Pick<
    SessionDiveGroupSupervision,
    'instructor' | 'diveGuide' | 'isAutonomous' | 'aptitude'
  >;
}) {
  const { instructor, diveGuide, isAutonomous, aptitude } = diveSupervision;

  if (instructor) {
    return `GP${aptitude ? ` ${aptitude}` : ''}`;
  } else if (diveGuide) {
    return 'GP';
  } else if (isAutonomous) {
    return 'AUTO';
  }
}

function formatSupervisionLevel({
  diveSupervision,
  staffMembersLevelFormat,
}: {
  diveSupervision: Pick<SessionDiveGroupSupervision, 'level' | 'aptitude'>;
  staffMembersLevelFormat: ExportStaffMemberLevelFormat;
}) {
  const { level, aptitude } = diveSupervision;
  if (staffMembersLevelFormat === 'level+details') {
    return [aptitude, level].filter((x) => !!x).join(' ');
  } else if (staffMembersLevelFormat === 'details') {
    return level ?? aptitude;
  }
  return aptitude ?? level;
}
