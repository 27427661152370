import { diveModeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { DiveCenterBookingPageLocalState } from '../../useDiveCenterBookingPageLocalState.hook';
import { DiveCenterBookingPageTab5Inquiries_InquiryBadge } from './DiveCenterBookingPageTab5Inquiries_InquiryBadge';
import { DiveCenterBookingPageTab5Inquiries_MemberNameLabel } from './DiveCenterBookingPageTab5Inquiries_MemberNameLabel';
import { CuApp_MemberInquiriesDetails } from './cuAppInquiriesByMemberBuilder';

export function DiveCenterBookingPageTab5Inquiries_Member({
  memberInquiriesDetails,
  localState,
  className,
}: {
  memberInquiriesDetails: CuApp_MemberInquiriesDetails;
  localState: DiveCenterBookingPageLocalState;
  className?: string;
}) {
  const { state, data } = localState;
  const { bookingMemberFull, activeInquiries, nextDiveModes } =
    memberInquiriesDetails;
  const clubSettings = data.aggregatedBookingResume.clubResume.clubSettings;
  return (
    <div
      className={clsx('cursor-pointer', className)}
      // onClick={() => {
      //   onClick(bookingMemberFull, 'edit');
      // }}
    >
      <div className="w-full flex gap-x-2 justify-between">
        <div className="w-full flex gap-y-1 gap-x-2 justify-between">
          <div
            className={'flex flex-col gap-x-2 flex-wrap sm:flex-row sm:gap-x-4'}
          >
            <div>
              <DiveCenterBookingPageTab5Inquiries_MemberNameLabel
                bookingMemberFull={bookingMemberFull}
                className={'sm:min-w-[15rem]'}
              />
              {nextDiveModes.length > 0 && (
                <div>
                  <div className="mt-2 text-sm font-medium">
                    Prochaines sorties:
                  </div>
                  <div className="text-sm font-medium text-app-primary">
                    {nextDiveModes
                      .map((diveMode) =>
                        diveModeFormatter.formatDiveMode(diveMode, {
                          format: 'long-label',
                        }),
                      )
                      .join(', ')}
                  </div>
                </div>
              )}
            </div>

            {activeInquiries.length > 0 && (
              <div className="flex gap-4 flex-wrap">
                {activeInquiries.map((inquiry) => (
                  <DiveCenterBookingPageTab5Inquiries_InquiryBadge
                    key={inquiry._id}
                    inquiry={inquiry}
                    bookingId={state.bookingId}
                    bookingMemberId={bookingMemberFull.bookingMember._id}
                    clubPublicReference={clubSettings.clubPublicReference}
                    status="pending"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {/* {hamburgerMenuItems?.length !== 0 && (
          <AppButtonMultiActions items={hamburgerMenuItems} />
        )} */}
      </div>
    </div>
  );
}
