import { AppDocRef } from '@mabadive/app-common-model';

export const appDocRefFormatter = {
  formatAppDocRef: formatAppDocRef,
};

function formatAppDocRef(appDocRef: AppDocRef): string {
  switch (appDocRef) {
    case 'medical-certificate':
      return 'Certificat médical';
    case 'medical-form':
      return 'Formulaire médical';
    case 'personal-photo':
      return `Photo d'identité`;
    case 'dive-certification':
      return 'Carte de certification';
    case 'insurance-proof':
      return "Justificatif d'assurance";
    case 'other':
      return 'Autre';
    default:
      return '';
  }
}
