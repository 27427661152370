/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppAuthenticatedCustomerAuthorizations } from '@mabadive/app-common-model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { LoadableContent } from 'src/business/_core/data/app-loading';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppRouter } from 'src/business/_core/data/hooks/useAppRouter';
import { DiverBookingCriteria } from 'src/business/_core/data/store-repository/diver-booking/model';
import {
  AppBreadcrumbItem,
  AppIconsAction,
} from 'src/business/_core/modules/layout';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import {
  useAppSecurityUser,
  useAppSecurityUserDiverAuthorizations,
} from 'src/business/auth/services';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageLoadedContent,
  DiveCenterBookingPageUpdateState,
} from '../DiveCenterBookingPage/model';
import { diveCenterBookingPageAggregatedBookingResumeBuilder } from '../DiveCenterBookingPage/services';
import { useDiveCenterBookingPageLoadedContent } from '../DiveCenterBookingPage/useDiveCenterBookingPageLoadedContent.hook';
import { DiveCenterBookingCheckoutPageViewMode } from './model';
import { BookingCheckoutEditMemberSubmitPanelFormModel } from './panels';
import { DiveCenterBookingMemberEditFormModel } from './panels/BookingCheckoutPanel2EditMembers/model';
import { useDiveCenterBookingCheckoutConfirmWithConsents } from './useDiveCenterBookingCheckoutConfirmWithConsents.hook';
import { useDiveCenterBookingCheckoutSaveDraftChanges } from './useDiveCenterBookingCheckoutSaveDraftChanges.hook';

export type DiveCenterBookingCheckoutPageLocalState = ReturnType<
  typeof useDiveCenterBookingCheckoutPageLocalState
>;

export function useDiveCenterBookingCheckoutPageLocalState({
  bookingId,
  diverId,
}: DiverBookingCriteria) {
  const securityUser = useAppSecurityUser();
  const az: AppAuthenticatedCustomerAuthorizations =
    useAppSecurityUserDiverAuthorizations();

  const {
    content: loadedContent,
    ...loadableContent
  }: LoadableContent<DiveCenterBookingPageLoadedContent> = useDiveCenterBookingPageLoadedContent(
    {
      bookingId,
      diverId,
    },
  );

  const [isSubmited, setIsSubmited] = useState(false);

  const [bookingMembersIdsInitialOrder, setBookingMembersIdsInitialOrder] =
    useState<string[]>();

  const { dataCore, aggregatedDataCore } =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregatedDataCore({
      loadedContent,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const [viewState, setViewStateInner] = useState<{
    mode: DiveCenterBookingCheckoutPageViewMode;
    participantsIndex?: number; // if (mode === 'checkout-edit-members')
  }>({
    mode: 'checkout-edit-member-start',
    // mode: 'checkout-edit-member-submit',
  });

  const setViewState = useCallback(
    (args: {
      mode: DiveCenterBookingCheckoutPageViewMode;
      participantsIndex?: number; // if (mode === 'checkout-edit-members')
    }) => {
      setViewStateInner(args);
    },
    [],
  );

  const initialBookingResume: DiveCenterBookingPageAggregatedBookingResume =
    useMemo(
      () =>
        (aggregatedDataCore?.bookingResumes ?? []).find(
          (x) => x.booking?._id === bookingId,
        ),
      [aggregatedDataCore?.bookingResumes, bookingId],
    );

  useEffect(() => {
    if (
      initialBookingResume?.bookingMembersIdsInitialOrder &&
      !bookingMembersIdsInitialOrder
    ) {
      setBookingMembersIdsInitialOrder(
        initialBookingResume?.bookingMembersIdsInitialOrder,
      );
    }
  }, [
    bookingMembersIdsInitialOrder,
    initialBookingResume?.bookingMembersIdsInitialOrder,
  ]);

  const initialFormValue: DiveCenterBookingMemberEditFormModel = useMemo(
    () => ({
      participants: (initialBookingResume?.bookingMembersFull ?? []).map(
        (existingBookingMemberFull) => ({
          bookingMember: existingBookingMemberFull?.bookingMember,
          diver: existingBookingMemberFull?.diver,
        }),
      ),
    }),
    [initialBookingResume?.bookingMembersFull],
  );
  const form = useForm<DiveCenterBookingMemberEditFormModel>({
    values: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const formValue = useWatch({
    control: form.control,
  }) as unknown as DiveCenterBookingMemberEditFormModel;

  const [updateState, setUpdateState] =
    useState<DiveCenterBookingPageUpdateState>({
      diversChanges: [],
      clubParticipantsChanges: [],
      bookingsChanges: [],
      bookingSessionsChanges: [],
      bookingMembersChanges: [],
      bookingSessionParticipantsChanges: [],
      bookingProductsChanges: [],
    });

  const hasAnyChange = useMemo(() => {
    const {
      diversChanges,
      clubParticipantsChanges,
      bookingsChanges,
      bookingSessionsChanges,
      bookingMembersChanges,
      bookingSessionParticipantsChanges,
      bookingProductsChanges,
    } = updateState;

    const changesCount =
      diversChanges?.length +
      clubParticipantsChanges?.length +
      bookingsChanges?.length +
      bookingSessionsChanges?.length +
      bookingMembersChanges?.length +
      bookingSessionParticipantsChanges?.length +
      bookingProductsChanges?.length;
    return changesCount !== 0;
  }, [updateState]);

  const aggregatedData =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregateAll({
      dataCore,
      updateState,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const { scrollToTop: scrollToTopCore } = useAppRouter();
  const scrollToTop = useCallback(() => {
    scrollToTopCore('app-page-content-container', {});
    // window.document.getElementById('app-page-content-container')?.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'auto',
    // });
  }, [scrollToTopCore]);

  const aggregatedBookingResume = useMemo(
    () =>
      aggregatedData.bookingResumes.find(
        (x) => x.booking?._id === initialBookingResume?.booking?._id,
      ),
    [aggregatedData.bookingResumes, initialBookingResume?.booking?._id],
  );
  const clubSettings = aggregatedBookingResume?.clubResume?.clubSettings;
  const clubResume = aggregatedBookingResume?.clubResume;
  const editableParticipantsCount =
    initialBookingResume?.editableParticipantsCount;

  const breadcrumbItems: AppBreadcrumbItem[] = useMemo(() => {
    const items: AppBreadcrumbItem[] = [
      {
        icon: AppIconsAction.checkout,
        label: 'Réservation',
        url: aggregatedBookingResume?.links.bookingCheckoutUrl,
        onClick: () => setViewState({ mode: 'checkout-edit-member-start' }),
      },
    ];
    if (viewState.mode === 'checkout-edit-members') {
      items.push({
        icon: AppIconsAction.addUser,
        label: 'Participants',
      });
    }

    return items;
  }, [
    aggregatedBookingResume?.links.bookingCheckoutUrl,
    setViewState,
    viewState.mode,
  ]);

  const onCancel = useCallback(async () => {
    setViewState({ mode: 'checkout-edit-member-start' });
    // if (
    //   !form.formState.isDirty ||
    //   (await confirmDialog.confirmPromise({
    //     title: 'Annuler les modifications?',
    //     message: 'Êtes-vous sûr de vouloir annuler vos modifications?',
    //     // consentMessage: "Oui, je ",
    //     // consentMessage2: gdprConsentMessage,
    //     // consentMessage2Description: gdprConsentMessageDescription,
    //     type: 'noYesDanger',
    //   }))
    // ) {
    //   onCancel();
    // }
  }, [setViewState]);

  const formState = form.formState;

  const saveDraftChanges = useDiveCenterBookingCheckoutSaveDraftChanges({
    updateState,
    setUpdateState,
    aggregatedBookingResume,
    initialFormValue,
  });

  const confirmWithConsents = useDiveCenterBookingCheckoutConfirmWithConsents({
    updateState,
    setUpdateState,
    aggregatedBookingResume,
  });

  const submitMemberChanges = useCallback(async () => {
    if (formState.isDirty) {
      await form.handleSubmit(async (formValue) => {
        await saveDraftChanges({
          formValue,
          isDraft: true,
        });
        form.reset({}, { keepValues: true });
      })();
    }
  }, [form, formState.isDirty, saveDraftChanges]);

  async function previousParticipant() {
    if (formState.isValid) {
      await submitMemberChanges();
      const isFirst = viewState.participantsIndex === 0;
      if (isFirst) {
        setViewState({ mode: 'checkout-edit-member-start' });
      } else {
        const participantsIndex =
          (viewState.participantsIndex - 1 + editableParticipantsCount) %
          editableParticipantsCount;
        setViewState({ ...viewState, participantsIndex });
      }
      scrollToTop();
    }
  }

  async function nextParticipant() {
    if (formState.isValid) {
      await submitMemberChanges();
      const isLast =
        viewState.participantsIndex + 1 === editableParticipantsCount;
      if (isLast) {
        setViewState({ mode: 'checkout-edit-member-preview' });
      } else {
        const participantsIndex =
          (viewState.participantsIndex + 1) % editableParticipantsCount;
        setViewState({ ...viewState, participantsIndex });
      }
      scrollToTop();
    }
  }

  async function lastParticipant() {
    if (formState.isValid) {
      await submitMemberChanges();
      const participantsIndex = editableParticipantsCount - 1;
      setViewState({ ...viewState, participantsIndex });
      scrollToTop();
    }
  }
  async function firstParticipant() {
    if (formState.isValid) {
      await submitMemberChanges();
      const participantsIndex = 0;
      setViewState({ ...viewState, participantsIndex });
      scrollToTop();
    }
  }
  // const editParticipantByIndex = useCallback((participantsIndex: number) => {
  //   // setMemberEditDialogState({
  //   //   isOpen: true,
  //   //   updateState,
  //   //   bookingResume: initialBookingResume,
  //   //   participantsIndex,
  //   // });
  //   setViewState({ mode: 'checkout-edit-members', participantsIndex });
  // }, []);

  async function editParticipantByIndex(participantsIndex: number) {
    if (formState.isValid || viewState.mode !== 'checkout-edit-members') {
      await submitMemberChanges();
      setViewState({ mode: 'checkout-edit-members', participantsIndex });
      scrollToTop();
    }
  }
  async function backToPreviousStep() {
    if (formState.isValid) {
      await submitMemberChanges();
      setViewState({ mode: 'checkout-edit-member-start' });
      scrollToTop();
    }
  }
  async function goNextStep() {
    if (formState.isValid) {
      await submitMemberChanges();
      setViewState({ mode: 'checkout-edit-member-preview' });
      scrollToTop();
    }
  }

  const redirectTo = useRedirect();

  async function submitConsents(
    form: UseFormReturn<BookingCheckoutEditMemberSubmitPanelFormModel, any>,
  ) {
    await form.handleSubmit(
      async (formValue) => {
        try {
          await confirmWithConsents({
            formValue,
          });
          uiStore.snackbarMessage.set({
            type: 'success',
            content: 'Informations envoyées!',
          });
          form.reset({}, { keepValues: true });
          redirectTo(aggregatedBookingResume?.links.bookingHomeUrl);
          scrollToTop();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(
            'Erreur inattendue pendant la soumission des informations',
            err,
          );
          appLogger.error(
            `Espace client: erreur soumission booking ${bookingId}`,
          );
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          scrollToTop();
        }
      },
      (err) => {
        uiStore.snackbarMessage.set({
          type: 'error',
          content: 'Veuillez accepter les consentements!',
        });
      },
    )();
  }

  const actions = {
    scrollToTop,
    editParticipantByIndex,
    previousParticipant,
    nextParticipant,
    firstParticipant,
    lastParticipant,
    submitConsents,
    backToPreviousStep,
    goNextStep,
  };

  return {
    securityUser,
    az,
    loadableContent,
    updateState,
    setUpdateState,
    aggregatedData,
    initialBookingResume,
    aggregatedBookingResume,
    viewState,
    setViewState,
    actions,
    hasAnyChange,
    isSubmited,
    setIsSubmited,
    clubSettings,
    clubResume,
    breadcrumbItems,
    form,
    formValue,
  };
}
