import {
  AppCurrency,
  ClubParticipantSpecialDiveType,
  ClubProductPackage,
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesDivePriceType,
  ClubProductPackageAttributesDiveType,
  ClubProductPackageAttributesProduct,
  ClubProductPackageAttributesSalesCriteria,
  ClubProductPackageAttributesTraining,
  ClubPublicSettings,
  ClubPurchasePackageExtraCost,
  DIVE_TRAININGS,
  DiveMode,
  DiveTrainingReference,
  FirstDiveTrainingReference,
} from '@mabadive/app-common-model';
import { clubResidentTypeFormatter } from '../../business/club-diver';
import { diveServiceFirstDiveFormatter } from '../offer';
import { diveServiceTrainingFormatter } from '../offer/diveServiceTrainingFormatter.service';
import { diveModeFormatter } from './diveModeFormatter.service';
import { productTypeFormatter } from './productTypeFormatter.service';

export type ClubProductPackageNameDetails = {
  prefix: string;
  name: string;
  label?: string;
  suffix?: string;
};

export type ClubProductPackageNameDetailsFormatProps = Pick<
  ClubProductPackage,
  'label' | 'namingConfiguration' | 'productType' | 'type'
> & {
  trainingAttributes?: Pick<
    ClubProductPackageAttributesTraining,
    'diveTrainingReference'
  >;
  diveAttributes?: Pick<
    ClubProductPackageAttributesDive,
    | 'diveMode'
    | 'minDepth'
    | 'maxDepth'
    | 'divesCount'
    | 'divesCountMax'
    | 'divesCountInfinite'
    | 'supervision'
    | 'equipment'
    | 'minDistance'
    | 'maxDistance'
    | 'divePriceType'
    | 'successiveDivesCount'
    | 'specialDiveType'
    | 'diveSingleAttributes'
    | 'firstDiveTrainingReference'
  >;
  productAttributes?: Pick<
    ClubProductPackageAttributesProduct,
    'defaultOrganizationReference'
  >;
  salesCriteria?: Pick<
    ClubProductPackageAttributesSalesCriteria,
    'residentType'
  >;
};

export const productPackageFormatter = {
  formatDescription,
  formatNameDetails,
  formatExtraCostsStringForExcelExport,
  formatNameString,
  formatNameStringVirtualOffer,
  formatDistanceDescription,
  formatDepthDescription,
  formatAgeDescription,
  formatMinMaxDivesCountDescription,
  formatSuccessiveDivesDescription,
  formatDivesCountDescription,
  formatProductTypeCategory,
};

function formatDescription(
  productPackage: Pick<
    ClubProductPackage,
    'type' | 'productAttributes' | 'diveAttributes' | 'trainingAttributes'
  >,
): string {
  if (
    productPackage.type === 'dive' &&
    productPackage.productAttributes.includeDives
  ) {
    const diveAttributes = productPackage?.diveAttributes;
    const diveType: ClubProductPackageAttributesDiveType =
      diveAttributes?.diveType;
    const diveMode: DiveMode = diveAttributes?.diveMode;
    if (diveType === 'scuba') {
      switch (diveMode) {
        case 'first-dive':
          return 'Baptême de plongée';
        case 'training':
          return 'Formation de plongée';
        // case 'theoretical-training':
        //   return 'Formation théorique';
        case 'supervised':
          return 'Plongeur encadré';
        case 'autonomous':
          return 'Plongeur autonome';
        case 'autoSupervised':
          return 'Auto-encadré';
        // case 'instructor':
        //   return 'Moniteur';
      }
      return 'Plongée';
    }
    if (diveType === 'free') {
      switch (diveMode) {
        case 'snorkeling':
          return diveModeFormatter.formatDiveMode('snorkeling', {
            format: 'short-label',
          });
        case 'snorkelingSupervised':
          return diveModeFormatter.formatDiveMode('snorkelingSupervised', {
            format: 'short-label',
          });
        case 'free-dive':
          return 'Apnée';
      }
      return 'Apnée';
    }
    if (diveType === 'observer') {
      return 'Accompagnateur';
    }
    return '???';
  }
  if (productPackage.type === 'training') {
    const trainingAttributes = productPackage?.trainingAttributes;

    const training = DIVE_TRAININGS.find(
      (x) => x.reference === trainingAttributes.diveTrainingReference,
    );

    if (training) {
      return diveServiceTrainingFormatter.formatTraining(training, {
        format: 'name',
      });
    }
    return 'Formation';
  }
}

function formatNameStringVirtualOffer({
  isSingleDive,
  successiveDivesCount,
  minDistance,
  maxDistance,
  diveMode,
  diveTrainingReference,
  groupAutonomousAndSupervised,
  firstDiveReference,
  publicSettings,
}: {
  isSingleDive: boolean;
  successiveDivesCount: number;
  minDistance: number;
  maxDistance: number;
  diveMode: Extract<
    DiveMode,
    | 'training'
    | 'supervised'
    | 'autonomous'
    | 'first-dive'
    | 'observer'
    | 'snorkeling'
    | 'snorkelingSupervised'
    | 'free-dive'
    | 'instructor'
    | 'autoSupervised'
  >;
  diveTrainingReference?: DiveTrainingReference; // type === 'training'
  groupAutonomousAndSupervised: boolean;
  firstDiveReference: FirstDiveTrainingReference;
  publicSettings: ClubPublicSettings;
}): string {
  let label: string;

  if (
    groupAutonomousAndSupervised &&
    (diveMode === 'autonomous' || diveMode === 'supervised')
  ) {
    label = isSingleDive ? `Plongée d'exploration` : `Plongées d'exploration`;
  } else {
    if (diveMode === 'training') {
      label = diveServiceTrainingFormatter.formatTrainingLabel(
        diveTrainingReference,
      );
    } else if (diveMode === 'autonomous') {
      label = isSingleDive ? 'Plongée autonome' : 'Plongées autonomes';
    } else if (diveMode === 'supervised') {
      label = isSingleDive ? 'Plongée encadrée' : 'Plongées encadrées';
    } else if (diveMode === 'autoSupervised') {
      label = isSingleDive ? 'Auto-encadré' : 'Auto-encadrés';
    } else if (diveMode === 'first-dive') {
      if (firstDiveReference) {
        // note: on perd le pluriel ici, mais on affiche la personnalisation du BPT
        label = diveServiceFirstDiveFormatter.format(firstDiveReference, {
          format: 'ref-name',
          publicSettings,
        });
      } else {
        label = isSingleDive ? 'Baptême' : 'Baptêmes';
      }
    } else if (diveMode === 'observer') {
      label = isSingleDive ? 'Accompagnateur' : 'Accompagnateurs';
    } else if (diveMode === 'snorkeling') {
      label = isSingleDive ? 'PMT' : 'PMT';
    } else if (diveMode === 'snorkelingSupervised') {
      label = isSingleDive ? 'Randonnée palmée' : 'Randonnées palmées';
    } else if (diveMode === 'free-dive') {
      label = isSingleDive ? 'Apnée' : 'Apnées';
    } else if (diveMode === 'instructor') {
      label = 'Guide de palanquée';
    } else {
      label = 'inconnu';
    }
  }

  const suffixes: string[] = [];

  if (minDistance > 0 || maxDistance > 0) {
    suffixes.push(formatDistanceDescription({ minDistance, maxDistance }));
  }
  if (successiveDivesCount > 1) {
    suffixes.push(
      successiveDivesCount === 2
        ? 'double'
        : successiveDivesCount === 3
        ? 'triple'
        : `x${successiveDivesCount}`,
    );
  }
  if (suffixes.length > 0) {
    label += ` (${suffixes.join(' - ')})`;
  }
  return label;
}
function formatProductTypeCategory(productPackage: ClubProductPackage): {
  type: string;
  category: string;
} {
  switch (productPackage?.type) {
    case 'dive':
      return {
        category: 'Sortie',
        type: diveModeFormatter.formatDiveMode(
          productPackage?.diveAttributes?.diveMode,
          { format: 'long-label' },
        ),
      };
    case 'training':
      return {
        category: 'Formation',
        type: productPackage.trainingAttributes?.diveTrainingReference,
      };
    case 'product':
      switch (productPackage?.productType) {
        case 'fee':
          return {
            category: 'Autre',
            type: 'Frais',
          };
        case 'rental':
          return {
            category: 'Autre',
            type: 'Location',
          };
        case 'sale':
          return {
            category: 'Autre',
            type: 'Vente',
          };
      }
  }
}

function formatNameString(
  productPackage: Pick<
    ClubProductPackage,
    | 'namingConfiguration'
    | 'diveAttributes'
    | 'trainingAttributes'
    | 'salesCriteria'
    | 'label'
    | 'productType'
  >,
  {
    options,
    publicSettings,
  }: {
    options?: ProductPackageFormatterOptions;
    publicSettings: ClubPublicSettings;
  },
): string {
  const { prefix, name, label, suffix } = formatNameDetails(productPackage, {
    options,
    publicSettings,
  });

  return [prefix, name, label, suffix].filter((x) => !!x).join(' ');
}

function formatExtraCostsStringForExcelExport(
  extraCosts: ClubPurchasePackageExtraCost[],
  { mainCurrency }: { mainCurrency: AppCurrency },
): string {
  if (extraCosts?.length > 0) {
    return extraCosts
      .map((x) => {
        return !x
          ? ''
          : [
              x.unitQuantity > 1 ? `x${x.unitQuantity}` : undefined,
              `${x.label ?? ''} ${x.comment ?? ''}`.trim(),
              x.unitPrice > 0
                ? `(${x.multiplier * x.unitQuantity * x.unitPrice} ${
                    mainCurrency.sign
                  })`
                : undefined,
            ]
              .filter((x) => x?.trim().length > 0)
              .join(' ');
      })
      .join('\r\n');
  }
}

export type ProductPackageFormatterOptions = {
  showResidentType?: boolean;
  showDepthInBilling?: boolean;
};

function formatNameDetails(
  productPackage: Pick<
    ClubProductPackageNameDetailsFormatProps,
    | 'namingConfiguration'
    | 'diveAttributes'
    | 'trainingAttributes'
    | 'salesCriteria'
    | 'label'
    | 'productType'
  >,
  {
    options,
    publicSettings,
  }: {
    options?: ProductPackageFormatterOptions;
    publicSettings: ClubPublicSettings;
  },
): ClubProductPackageNameDetails {
  if (productPackage?.namingConfiguration?.overrideDefaultName?.enabled) {
    return {
      prefix: productPackage?.namingConfiguration?.overrideDefaultName.prefix,
      name: productPackage?.namingConfiguration?.overrideDefaultName.name,
      label: productPackage?.namingConfiguration?.overrideDefaultName.label,
      suffix: productPackage?.namingConfiguration?.overrideDefaultName.suffix,
    };
  }

  let nameDetails: ClubProductPackageNameDetails;

  const namingConfiguration = productPackage?.namingConfiguration;
  const diveAttributes = productPackage?.diveAttributes;
  const trainingAttributes = productPackage?.trainingAttributes;
  const salesCriteria = productPackage?.salesCriteria;
  const diveMode: DiveMode = diveAttributes?.diveMode;
  const specialDiveType: ClubParticipantSpecialDiveType =
    diveAttributes?.specialDiveType;
  const divesCount = diveAttributes?.divesCount;
  const divePriceType = diveAttributes?.divePriceType;
  const successiveDivesCount = diveAttributes?.successiveDivesCount;

  const divesCountMax = diveAttributes?.divesCountMax;
  const divesCountInfinite = diveAttributes?.divesCountInfinite;
  const supervision = diveAttributes?.supervision;
  const equipment = diveAttributes?.equipment;

  const suffixes: string[] = [];

  if (!diveAttributes) {
    // non-dive products
    nameDetails = {
      prefix: '',
      name:
        productPackage.label ??
        productTypeFormatter.format(productPackage.productType),
    };
    return nameDetails;
  }

  const countSuffix = buildCountSuffixIfMultiple({
    divePriceType,
    successiveDivesCount,
    divesCount,
    divesCountMax,
    divesCountInfinite,
  });

  switch (diveMode) {
    case 'snorkeling':
      nameDetails = {
        prefix: `PMT${countSuffix}`,
        name: diveModeFormatter.formatDiveMode('snorkeling', {
          format: 'short-label',
        }),
      };
      break;
    case 'snorkelingSupervised':
      nameDetails = {
        prefix: `RP${countSuffix}`,
        name: diveModeFormatter.formatDiveMode('snorkelingSupervised', {
          format: 'short-label',
        }),
      };
      break;
    case 'autoSupervised':
      nameDetails = {
        prefix: `AE${countSuffix}`,
        name: diveModeFormatter.formatDiveMode('autoSupervised', {
          format: 'short-label',
        }),
      };
      break;
    case 'free-dive': {
      nameDetails = {
        prefix: `APN${countSuffix}`,
        name: 'Apnée',
      };
      break;
    }
    case 'observer': {
      nameDetails = {
        prefix: `ACC${countSuffix}`,
        name: 'Accompagnateur',
      };
      break;
    }
    case 'instructor': {
      nameDetails = {
        prefix: `GP${countSuffix}`,
        name: diveModeFormatter.formatDiveMode(diveMode, {
          format: 'long-label',
        }),
      };
      break;
    }
    case 'first-dive': {
      let basePrefix = 'BPT';
      if (diveAttributes?.firstDiveTrainingReference) {
        const customType = publicSettings?.services?.firstDives?.customize
          ? publicSettings?.services?.firstDives?.customTypes.find(
              (x) =>
                x.originalRef === diveAttributes.firstDiveTrainingReference,
            )
          : undefined;
        if (customType) {
          basePrefix = customType.labelShort;
        }
      }
      nameDetails = {
        prefix: `${basePrefix}${countSuffix}`,
        name: 'Baptême',
      };
      break;
    }
    case 'supervised':
    case 'autonomous': {
      const supervisionLabel = namingConfiguration?.includeSupervision
        ? supervision === 'supervised'
          ? 'encadré'
          : supervision === 'autonomous'
          ? 'autonome'
          : null
        : null;
      const equipmentLabel = namingConfiguration?.includeEquipment
        ? equipment === 'equipped'
          ? 'éq.perso'
          : equipment === 'not-equipped'
          ? 'éq.club'
          : null
        : null;
      const name = [supervisionLabel, equipmentLabel]
        .filter((x) => !!x)
        .join('/');

      nameDetails = {
        prefix: countSuffix,
        name,
      };
      if (divesCountInfinite) {
        suffixes.push('illimité');
      }

      break;
    }
    case 'training': {
      const training = DIVE_TRAININGS.find(
        (x) => x?.reference === trainingAttributes?.diveTrainingReference,
      );

      // il n'est pas obligatoire de renseigner le type de formation
      nameDetails = {
        prefix: training?.reference,
        name: training?.label ?? 'Formation',
      };
      break;
    }
  }

  if (!nameDetails) {
    nameDetails = {
      prefix: '?',
      name: '',
    };
  }

  if (productPackage.label?.trim().length > 0) {
    nameDetails.label = productPackage.label;
    if (!namingConfiguration?.includeDefaultName) {
      nameDetails.name = '';
    }
  }

  if (diveMode === 'training') {
    // if (productPackage.productAttributes.defaultOrganizationReference) {
    //   nameDetails.suffix =
    //     productPackage.productAttributes.defaultOrganizationReference;
    // }
  } else {
    if (diveAttributes.minDistance > 0 || diveAttributes.maxDistance > 0) {
      suffixes.push(formatDistanceDescription(diveAttributes));
    }
    if (
      diveAttributes.diveSingleAttributes?.minDivesCount > 0 ||
      diveAttributes.diveSingleAttributes?.maxDivesCount > 0
    ) {
      suffixes.push(
        formatMinMaxDivesCountDescription(diveAttributes.diveSingleAttributes),
      );
    }
    if (
      (options?.showDepthInBilling &&
        diveMode === 'supervised' &&
        diveAttributes.minDepth > 0) ||
      diveAttributes.maxDepth > 0
    ) {
      suffixes.push(
        productPackageFormatter.formatDepthDescription(diveAttributes),
      );
    }

    // if (specialDiveType) {
    //   suffixes.push(
    //     participantSpecialDiveTypeFormatter.formatSpecialDiveType(
    //       specialDiveType,
    //     ),
    //   );
    // }
  }
  if (salesCriteria?.residentType && options?.showResidentType) {
    suffixes.push(
      clubResidentTypeFormatter.format(salesCriteria?.residentType),
    );
  }
  nameDetails.suffix = suffixes.join(' ');

  return nameDetails;
}

function buildCountSuffixIfMultiple({
  divePriceType,
  successiveDivesCount,
  divesCount,
  divesCountMax,
  divesCountInfinite,
}: {
  divePriceType: ClubProductPackageAttributesDivePriceType;
  successiveDivesCount: number;
  divesCount: number;
  divesCountMax: number;
  divesCountInfinite: boolean;
}) {
  const isSuccessive =
    divePriceType === 'successive' && successiveDivesCount > 1;

  if (isSuccessive) {
    return `${divesCount}x${successiveDivesCount}`;
  }
  if (!divesCountInfinite) {
    return ` x${divesCount}${
      divesCountMax > divesCount ? `-${divesCountMax}` : ''
    }`;
  }
  return '';
}

function formatMinMaxDivesCountDescription({
  minDivesCount,
  maxDivesCount,
}: {
  minDivesCount?: number;
  maxDivesCount?: number;
}) {
  // ATTENTION: maxDistance est INCLUSIF (contrairement à la distance)
  if (minDivesCount > 0 || maxDivesCount > 0) {
    return maxDivesCount > 0
      ? `${minDivesCount ?? 0}-${maxDivesCount} plongées`
      : `>= ${minDivesCount ?? 0} plongées`;
  }
}

function formatSuccessiveDivesDescription({
  successiveDivesCount,
}: {
  successiveDivesCount?: number;
}) {
  if (successiveDivesCount > 1) {
    return `${successiveDivesCount} blocs`;
  }
}
function formatDivesCountDescription({
  divesCount,
  successiveDivesCount,
}: {
  divesCount?: number;
  successiveDivesCount?: number;
}) {
  if (successiveDivesCount > 1) {
    return `${divesCount} x ${successiveDivesCount}`;
  }
  return `${divesCount}`;
}

function formatDistanceDescription({
  minDistance,
  maxDistance,
}: {
  minDistance?: number;
  maxDistance?: number;
}) {
  // ATTENTION: maxDistance est EXCLUSIF
  maxDistance = maxDistance - 1; // maxDistance est exclusif, mais à l'affichage on indique la distance inclusive
  if (minDistance > 0 || maxDistance > 0) {
    // if (minDistance > 0) {
    return maxDistance > 0
      ? `${minDistance ?? 0}-${maxDistance} nmi`
      : `>= ${minDistance ?? 0} nmi`;
    // }
    // return `<= ${maxDistance} nmi`;
  }
}
function formatDepthDescription({
  minDepth,
  maxDepth,
}: {
  minDepth?: number;
  maxDepth?: number;
}) {
  // ATTENTION: minDepth est EXCLUSIF
  if (minDepth > 0) {
    // NOTE: on n'affiche que si minDepth est spécifié, sinon ça rajoute le suffix sur toutes les prestations ou on a précisé la prof maxi
    minDepth = minDepth + 1; // minDepth est inclusif, mais à l'affichage on indique la profondeur inclusive
    if (minDepth > 0) {
      return maxDepth > 0
        ? `${minDepth ?? 0}-${maxDepth} m`
        : `>= ${minDepth ?? 0} m`;
    }
    return `<= ${maxDepth} m`;
  }
}

function formatAgeDescription({
  minAge,
  maxAge,
}: {
  minAge?: number;
  maxAge?: number;
}) {
  if (minAge > 0 || maxAge > 0) {
    if (minAge > 0) {
      return maxAge > 0 ? `${minAge}-${maxAge} ans` : `>= ${minAge} ans`;
    }
    return `<= ${maxAge} ans`;
  }
}
