import {
  AppInquiry,
  ClubDiver,
  DiveMode,
} from '../../../../app-common-model/src';
import { dateService } from '../../data';

export const appInquiryFilter = {
  findActiveInquiries,
};

function findActiveInquiries<T extends Pick<AppInquiry, 'settings'>>(
  allInquiries: T[],
  {
    diver,
    nextDiveModes,
  }: {
    diver: Pick<ClubDiver, 'birthdate' | 'age'>;
    nextDiveModes: DiveMode[];
  },
): T[] {
  return allInquiries.filter((inquiry) => {
    const settings = inquiry.settings;
    const aa = settings.autoActivation;
    // TODO gérer la modification manuelle de la configuration (et le cas où le questionnaire est déjà rempli)
    if (!aa?.enabled) {
      return false;
    }
    if (
      aa?.diveModes?.length > 0 &&
      !haveIntersection(nextDiveModes, aa?.diveModes)
    ) {
      return false;
    }
    if (aa?.age?.min > 0 || aa?.age?.max > 0) {
      // check future participant age
      const refDate = new Date();

      const age =
        diver && diver.birthdate
          ? dateService.getAge(diver.birthdate, refDate)
          : diver.age;

      if (!age) {
        return false; // age inconnu
      }
      if (aa.age.min > 0 && age < aa.age.min) {
        return false; // trop jeune
      }
      if (aa.age.max > 0 && age > aa.age.max) {
        return false; // trop vieux
      }
    }
    return true;
  });
}
function haveIntersection(arr1: string[], arr2: string[]): boolean {
  const set1 = new Set(arr1);
  for (const item of arr2) {
    if (set1.has(item)) {
      return true;
    }
  }
  return false;
}
