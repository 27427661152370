/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Booking,
  Customer_ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { YES_NO_OPTIONS } from 'src/business/dive-center/components';
import { AppInputRHF, AppToogleButtonGroupRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormNewsletter = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const baseFormName = `participants.${participantsIndex}` as const;

  const newsletterSettings =
    clubSettings.publicSettings?.customerSpace?.features?.newsletter;

  const [newsLetterRegistrationEnabled] = useWatch({
    control,
    name: [
      `${baseFormName}.diver.details.newsletter.registrationActive` as const,
    ],
  });

  const newsletterMessage = useMemo(() => {
    if (
      newsletterSettings?.customize &&
      newsletterSettings?.message?.trim()?.length > 0
    ) {
      return newsletterSettings?.message;
    }
    return 'Je souhaite recevoir la newsletter';
  }, [newsletterSettings?.customize, newsletterSettings?.message]);

  return (
    <div className={`w-screen flex flex-col gap-4 ${className}`}>
      <div className={'w-full sm:max-w-[24rem]'}>
        <AppFormControlRHF_Deprecated
          className={'md:col-span-4 w-full'}
          label={newsletterMessage}
          control={control}
          name={
            `${baseFormName}.diver.details.newsletter.registrationActive` as const
          }
          renderComponent={(props) => (
            <AppToogleButtonGroupRHF
              {...props}
              color="boolean"
              theme="strong"
              items={YES_NO_OPTIONS}
              // selected={selected}
              // onChanges={onChange}
            />
          )}
        />
      </div>
      {newsLetterRegistrationEnabled && (
        <AppFormControlRHF_Deprecated
          className={'md:col-span-2 w-full'}
          label="Adresse e-mail"
          control={control}
          validation={{
            email: true,
          }}
          name={`${baseFormName}.diver.emailAddress` as const}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      )}
    </div>
  );
};
