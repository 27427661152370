import { DiveMode } from '@mabadive/app-common-model';

export type DiveModeStringFormat =
  | 'short-ref'
  | 'short-label'
  | 'long-label'
  | 'short-ref-label';

export const diveModeFormatter = {
  formatDiveMode,
};

function formatDiveMode(
  diveMode: DiveMode,
  { format }: { format: DiveModeStringFormat },
): string {
  if (format === 'short-ref') {
    switch (diveMode) {
      case 'observer':
        return 'ACC';
      case 'snorkeling':
        return 'PMT';
      case 'snorkelingSupervised':
        return 'RP';
      case 'first-dive':
        return 'BPT';
      case 'training':
        return 'FN';
      case 'theoretical-training':
        return 'FT';
      case 'supervised':
        return 'PE';
      case 'autonomous':
        return 'PA';
      case 'autoSupervised':
        return 'AE';
      case 'free-dive':
        return 'APN';
      case 'instructor':
        return 'GP';
      case 'unknown':
        return '???';
    }
  }
  if (format === 'short-label') {
    switch (diveMode) {
      case 'observer':
        return 'Accompagnateur';
      case 'snorkeling':
        return 'PMT / Snorkeling';
      case 'snorkelingSupervised':
        return 'Randonnée palmée';
      case 'first-dive':
        return 'Baptême';
      case 'training':
        return 'Formation';
      case 'theoretical-training':
        return 'Formation théorique';
      case 'supervised':
        return 'Encadré';
      case 'autonomous':
        return 'Autonome';
      case 'autoSupervised':
        return 'Auto-encadré';
      case 'free-dive':
        return 'Apnée';
      case 'instructor':
        return 'Guide de palanquée';
      case 'unknown':
        return '???';
    }
  }
  if (format === 'long-label') {
    switch (diveMode) {
      case 'observer':
        return 'Accompagnateur';
      case 'snorkeling':
        return 'PMT / Snorkeling';
      case 'snorkelingSupervised':
        return 'Randonnée palmée';
      case 'first-dive':
        return 'Baptême';
      case 'training':
        return 'Formation';
      case 'theoretical-training':
        return 'Formation théorique';
      case 'supervised':
        return 'Plongeur Encadré';
      case 'autonomous':
        return 'Plongeur Autonome';
      case 'autoSupervised':
        return 'Plongeur Auto-encadré';
      case 'free-dive':
        return 'Apnée';
      case 'instructor':
        return 'Guide de palanquée';
      case 'unknown':
        return ' Inconnu';
    }
  }
  // format : short-ref-label
  switch (diveMode) {
    case 'observer':
      return 'ACC - Accompagnateur';
    case 'snorkeling':
      return 'PMT - Snorkeling';
    case 'snorkelingSupervised':
      return 'RP - Randonnée palmée';
    case 'first-dive':
      return 'BPT - Baptême';
    case 'training':
      return 'FN - Formation';
    case 'theoretical-training':
      return 'FT - Formation théorique';
    case 'supervised':
      return 'PE - Plongeur Encadré';
    case 'autonomous':
      return 'PA - Plongeur Autonome';
    case 'autoSupervised':
      return 'AE - Plongeur Auto-encadré';
    case 'free-dive':
      return 'APN - Apnée';
    case 'instructor':
      return 'GP - Guide de palanquée';
    case 'unknown':
      return '??? - Inconnu';
  }
  // default
  return diveMode;
}
