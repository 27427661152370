/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppInquiryFullGql_Customer } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppIconsInquiry } from '../../../_core/modules/layout';
import { DiveCenterBookingInquiryPage_FormModel } from './model';
import { DiveCenterBookingInquiryPageLocalState } from './useDiveCenterBookingInquiryPageLocalState.hook';

export const DiveCenterBookingInquiryPage_FormHeader = ({
  inquiry,
  localState,
  form,
  className,
}: {
  inquiry: AppInquiryFullGql_Customer;
  localState: DiveCenterBookingInquiryPageLocalState;
  form: UseFormReturn<DiveCenterBookingInquiryPage_FormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const inquiryType = inquiry.type;
  const InquiryIcon = AppIconsInquiry[inquiryType];

  return (
    <div
      className={clsx('app-card p-4 border-t-8', className)}
      style={{
        borderColor: inquiry.settings.color,
      }}
    >
      <div
        className={clsx(
          'py-2 flex items-center gap-4 text-xl font-bold uppercase',
        )}
        style={{
          color: inquiry.settings.color,
        }}
      >
        {InquiryIcon && <InquiryIcon className="h-6 w-6" />}
        {inquiry.name}
      </div>
    </div>
  );
};
