import { ClubBoatDepartureType } from '@mabadive/app-common-model';
import { clubBoatFormatter } from './clubBoatFormatter.service';

export const CLUB_RESSOURCES_TYPES: {
  departureType: ClubBoatDepartureType;
  defaultLabel: string;
  defaultColor: string;
  legendDescription: string;
}[] = [
  {
    departureType: 'boat',
    defaultLabel: clubBoatFormatter.formatDepartureType('boat'),
    defaultColor: '#821727',
    legendDescription: 'Départ en bateau (plongée, PMT, sortie baleine...)',
  },
  {
    departureType: 'border',
    defaultLabel: clubBoatFormatter.formatDepartureType('border'),
    defaultColor: '#9b8c03',
    legendDescription: 'Départ du bord, de la plage...',
  },
  {
    departureType: 'pool',
    defaultLabel: clubBoatFormatter.formatDepartureType('pool'),
    defaultColor: '#149ceb',
    legendDescription: 'Plongée en piscine (formation, baptême...)',
  },
  {
    departureType: 'room',
    defaultLabel: clubBoatFormatter.formatDepartureType('room'),
    defaultColor: '#50413a',
    legendDescription: 'Cours théoriques',
  },
];
