/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Booking,
  Customer_ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { YES_NO_OPTIONS } from 'src/business/dive-center/components';
import { AppInputRHF, AppToogleButtonGroupRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormInsurance = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const insuranceSettings =
    clubSettings.publicSettings?.customerSpace?.features?.insurance;

  const baseFormName = `participants.${participantsIndex}` as const;

  const [hasInsurance] = useWatch({
    control,
    name: [
      `${baseFormName}.bookingMember.details.insurance.hasInsurance` as const,
    ],
  });

  const pendingList = booking.bookingCustomerConfig?.pendingList;

  const insuranceMessage = useMemo(() => {
    if (
      insuranceSettings?.customize &&
      insuranceSettings?.message?.trim()?.length > 0
    ) {
      return insuranceSettings?.message;
    }
    return undefined; // pas de message par défaut
  }, [insuranceSettings?.customize, insuranceSettings?.message]);

  return (
    <div className={`w-full max-w-[97vw] flex flex-col ${className}`}>
      {pendingList?.diversDivingInfo && (
        <>
          <div className={'w-full max-w-full sm:max-w-[24rem]'}>
            <AppFormControlRHF_Deprecated
              className={'md:col-span-4 w-full'}
              label="Ce participant a-t-il une assurance"
              control={control}
              name={
                `${baseFormName}.bookingMember.details.insurance.hasInsurance` as const
              }
              renderComponent={(props) => (
                <AppToogleButtonGroupRHF
                  {...props}
                  color="boolean"
                  theme="strong"
                  items={YES_NO_OPTIONS}
                  // selected={selected}
                  // onChanges={onChange}
                />
              )}
            />
          </div>
          {hasInsurance && (
            <div
              className={
                'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
              }
            >
              <AppFormControlRHF_Deprecated
                className="md:col-span-2 w-full"
                label="Nom assureur et numéro assurance"
                control={control}
                name={
                  `${baseFormName}.bookingMember.details.insurance.number` as const
                }
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" type="text" {...props} />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="md:col-span-2 w-full"
                label="Date d'expiration"
                control={control}
                name={
                  `${baseFormName}.bookingMember.details.insurance.expirationDate` as const
                }
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    className="w-full"
                    {...props}
                  />
                )}
              />
            </div>
          )}
          {hasInsurance === false && (
            <>
              <p className="mt-2 text-sm text-status-error">
                Si vous n’avez pas d’assurance, nous avons besoin de votre N° de
                carte d'identité ou passeport:
              </p>
              <div
                className={
                  'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                <AppFormControlRHF_Deprecated
                  className={'md:col-span-2 w-full'}
                  label="N° CNI ou Passeport"
                  control={control}
                  name={
                    `${baseFormName}.diver.details.identificationNumber` as const
                  }
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth />
                  )}
                />
              </div>
              {insuranceMessage && (
                <p className="mt-2 text-sm text-app-blue">{insuranceMessage}</p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
