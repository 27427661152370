import {
  AppInquiryForClubResumeGql_Customer,
  Customer_ClubResumePublic,
  DiveMode,
} from '@mabadive/app-common-model';
import { appInquiryFilter, dateService } from '@mabadive/app-common-services';
import { CUS_BookingMemberFull } from '../../../../../club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../model';

export type CuApp_MemberInquiriesDetails = {
  bookingMemberFull: CUS_BookingMemberFull;
  activeInquiries: AppInquiryForClubResumeGql_Customer[];
  nextDiveModes: DiveMode[];
};

export const cuAppInquiriesByMemberBuilder = {
  buildInquiriesByMemberModel,
};
function buildInquiriesByMemberModel({
  aggregatedBookingResume,
  clubResume,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  clubResume: Customer_ClubResumePublic;
}): CuApp_MemberInquiriesDetails[] {
  if (!aggregatedBookingResume || !clubResume) {
    return []; // data not loaded
  }
  const { bookingMembersFull, bookingParticipantsFull } =
    aggregatedBookingResume;
  return bookingMembersFull.map((bookingMemberFull) => {
    const diver = bookingMemberFull.diver;
    const memberBookingParticipantsFull = bookingParticipantsFull.filter(
      (bpf) => bpf.diver?._id === diver._id,
    );
    // only next
    const nextMemberBookingParticipantsFull =
      memberBookingParticipantsFull?.filter(
        (bpf) =>
          dateService.isTodayUTC(bpf?.diveSession?.time) ||
          dateService.isFutureDayUTC(bpf?.diveSession?.time),
      );
    const diveModes = nextMemberBookingParticipantsFull?.reduce((acc, bpf) => {
      if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
        acc.push(bpf?.diveSessionParticipant?.diveMode);
      }
      return acc;
    }, [] as DiveMode[]);
    const nextDiveModes = [...new Set(diveModes)];
    const enabledInquiries = (clubResume.inquiries ?? []).filter(
      (inquiry) => inquiry.settings?.enabled,
    );
    const activeInquiries = appInquiryFilter.findActiveInquiries(
      enabledInquiries,
      {
        nextDiveModes,
        diver,
      },
    );
    return {
      bookingMemberFull,
      activeInquiries,
      nextDiveModes,
    };
  });
}
