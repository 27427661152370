import { stringFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { AppMultilineText } from 'src/business/club/modules/_common/form';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../model';

export function DiveCenterBookingPageTab1BookingResumeMessageCard({
  aggregatedBookingResume,
  messageToDisplay,
  linkToDisplay,
  children,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  messageToDisplay: string;
  linkToDisplay: string;
  children?: React.ReactNode | React.ReactNode[];
}) {
  const { clubResume, diveCenter } = aggregatedBookingResume;

  const clubSettings = clubResume?.clubSettings;
  const diveCenterPublicData = diveCenter?.publicData;

  const logoSquareUrl = stringFormatter.buildCDNUrl(
    appWebConfig.applications.cdnUrl,
    clubSettings.publicData.brand.logoSquareUrl,
  );

  return messageToDisplay || linkToDisplay || children ? (
    <div className="app-card app-p-content">
      <div className="grid gap-2">
        {(messageToDisplay || linkToDisplay) && (
          <>
            <div className="uppercase font-bold text-base text-gray-800">
              Message du centre de plongée:
            </div>
            <blockquote className="mt-2 relative p-4 text-xl bg-gray-50 text-gray-600 border border-l-4 border-gray-100 border-l-gray-400 quote">
              <cite className="flex items-center not-italic">
                {logoSquareUrl && (
                  <div className={'hidden sm:block mr-4'}>
                    <img
                      src={logoSquareUrl}
                      alt="Logo"
                      className="w-16 mr-4 rounded bg-white border border-gray-400"
                    />
                  </div>
                )}
                <div className="flex flex-col items-start text-sm">
                  <span className="mb-1 text-sm font-bold text-app-primary uppercase">
                    {diveCenterPublicData?.brand?.name}
                  </span>
                  {messageToDisplay && (
                    <AppMultilineText
                      className="text-base"
                      lineClassName="my-0.5"
                      text={messageToDisplay}
                    />
                  )}

                  {linkToDisplay && (
                    <a
                      className="my-2 underline text-app-primary text-base font-medium"
                      href={linkToDisplay}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {linkToDisplay}
                    </a>
                  )}
                </div>
              </cite>
            </blockquote>
          </>
        )}

        {children && children}
      </div>
    </div>
  ) : null;
}
