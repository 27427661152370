import {
  ClubDiverConsentAcceptance,
  buildDefaultRGPDAgreementMessage,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  AppBreadcrumbBar,
  AppMessageLight,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { AppMultilineText } from 'src/business/club/modules/_common/form';
import { AppInputBooleanRHF } from 'src/lib/form';
import { DiveCenterBookingCheckoutPageLocalState } from '../../useDiveCenterBookingCheckoutPageLocalState.hook';
import { BookingCheckoutEditMemberSubmitPanelActionsBar } from './BookingCheckoutEditMemberSubmitPanelActionsBar';

export type BookingCheckoutEditMemberSubmitPanelFormModel = {
  consents: {
    validInfo: ClubDiverConsentAcceptance;
    gdpr: ClubDiverConsentAcceptance;
  };
};
// export type ClubDiverConsentAcceptance = {
//   id: 'valid-info' | 'gdpr'; // on va pouvoir ajouter des consentements, genre cgv
//   value: boolean;
//   date?: Date;
// };

export const BookingCheckoutEditMemberSubmitPanel = ({
  localState,
}: {
  localState: DiveCenterBookingCheckoutPageLocalState;
}) => {
  const {
    loadableContent,
    aggregatedBookingResume,
    clubSettings,
    setViewState,
    breadcrumbItems,
    actions,
  } = localState;
  const { diveCenter, links, checkoutState } = aggregatedBookingResume;

  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;

  const gdprSettings =
    clubSettings?.publicSettings?.customerSpace?.consents?.gdpr;

  const contact = clubSettings?.publicData?.contact;
  const brand = clubSettings?.publicData?.brand;

  const brandFullName = useMemo(
    () => `${brand?.name}, ${brand?.locationResume}`,
    [brand?.locationResume, brand?.name],
  );

  const {
    gdprConsentAgreementId,
    gdprConsentMessage,
    gdprConsentMessageDescription,
  } = useMemo(() => {
    if (gdprSettings?.enabled) {
      const gdprConsentMessageDescription =
        gdprSettings?.customize &&
        gdprSettings?.consentAgreement?.message?.trim()?.length > 0
          ? gdprSettings?.consentAgreement?.message
          : buildDefaultRGPDAgreementMessage({
              brandFullName,
              contactEmailAddress: contact?.emailAddress,
            });
      const gdprConsentMessage =
        'Je reconnais avoir pris connaissance des informations sur le RGPD.';
      return {
        gdprConsentMessageDescription,
        gdprConsentMessage,
        gdprConsentAgreementId: gdprSettings?.consentAgreement?._id,
      };
    }
    return {
      gdprConsentMessageDescription: undefined,
      gdprConsentMessage: undefined,
      gdprConsentAgreementId: undefined,
    };
  }, [
    brandFullName,
    contact?.emailAddress,
    gdprSettings?.consentAgreement?._id,
    gdprSettings?.consentAgreement?.message,
    gdprSettings?.customize,
    gdprSettings?.enabled,
  ]);

  const initialValue: BookingCheckoutEditMemberSubmitPanelFormModel = useMemo(
    () => ({
      consents: {
        gdpr: {
          type: 'gdpr',
          consentAgreementId: gdprConsentAgreementId,
          accepted: false,
          date: new Date(),
        },
        validInfo: {
          type: 'valid-info',
          consentAgreementId: undefined,
          accepted: false,
          date: new Date(),
        },
      },
    }),
    [gdprConsentAgreementId],
  );

  const form = useForm<BookingCheckoutEditMemberSubmitPanelFormModel>({
    defaultValues: initialValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { control, register, handleSubmit, reset, watch, trigger, formState } =
    form;

  const [validInfoConsent, gdprConsent] = useWatch({
    control,
    name: ['consents.validInfo.accepted', 'consents.gdpr.accepted'],
  });

  return (
    <AppPageContainerWithFixedBars
      contentState={loadableContent.contentState}
      lastActionStatus={loadableContent.lastActionStatus}
      marginBottom={true}
      paddingBottom={true}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <AppBreadcrumbBar
          className="my-4"
          color={'#333'}
          items={breadcrumbItems}
        />
      )}
      footerBar={() => (
        <BookingCheckoutEditMemberSubmitPanelActionsBar
          className="mt-8 mb-2 mx-4 sm:hidden"
          localState={localState}
          form={form}
        />
      )}
    >
      <div className="grid gap-4">
        <div className="app-card app-p-content grid gap-4">
          <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
            Confirmer l'envoi de mes informations
          </h3>

          <div className="text-base grid gap-4">
            <div className="">
              <AppMessageLight
                type="info"
                className="mt-4 mb-2 text-sm md:text-base"
              >
                <p className="font-bold text-status-info my-0.5 sm:my-1 md:my-1.5">
                  Êtes-vous sûr d'avoir renseigné l'ensemble des informations,
                  et de vouloir les transmettre au centre de plongée?
                </p>
                <p className="my-0.5 sm:my-1 md:my-1.5">
                  Veuillez accepter les conditions ci-dessous et cliquer sur le
                  bouton "Envoyer mes informations" ci-dessous.
                </p>
                <AppInputBooleanRHF
                  className="my-4"
                  control={control}
                  name={'consents.validInfo.accepted'}
                  required={true}
                  labelFontClassName={'sm:text-sm sm:ml-1'}
                  labelColorClassName={
                    validInfoConsent
                      ? 'font-bold text-app-blue'
                      : 'font-bold text-status-error'
                  }
                  type="checkbox"
                  description={
                    "Je confirme l'exactitude des informations renseignées"
                  }
                />
              </AppMessageLight>
            </div>

            {gdprSettings?.enabled && (
              <div className="">
                <AppMessageLight
                  type="info"
                  className="mt-4 mb-2 text-sm md:text-base"
                >
                  <AppMultilineText
                    className="my-2"
                    lineClassName="my-0.5 sm:my-1 md:my-1.5"
                    text={gdprConsentMessageDescription}
                  />
                  <AppInputBooleanRHF
                    className="my-4"
                    control={control}
                    name={'consents.gdpr.accepted'}
                    required={true}
                    labelFontClassName={'sm:text-sm sm:ml-1'}
                    labelColorClassName={
                      gdprConsent
                        ? 'font-bold text-app-blue'
                        : 'font-bold text-status-error'
                    }
                    type="checkbox"
                    description={gdprConsentMessage}
                  />
                </AppMessageLight>
              </div>
            )}
          </div>
          <BookingCheckoutEditMemberSubmitPanelActionsBar
            className="hidden sm:flex my-8"
            localState={localState}
            form={form}
          />
        </div>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
