"use strict";
exports.__esModule = true;
exports.DEFAULT_CLUB_SETTINGS_CUSTOMER = void 0;
var general = {
    externalRef: false,
    homeAddress: true,
    phoneNumber2: true,
    languageCode: true,
    size: false,
    weight: false,
    shoeSize: false,
    age: false,
    // birthDate: true, TODO ajouter la date de naissance (pour bien comprendre qu'elle est aussi affichée)
    customerOrigin: true,
    sanitaryPass: false,
    residentType: true,
    identificationNumber: true,
    newsletter: true,
    accomodation: false,
    pickup: false,
    isCustomer: false,
    isMember: false,
    memberRef: false,
    membershipDate: false,
    isStudent: false,
    studentDescription: false
};
var diving = {
    medicalCertificateDate: true,
    medicalCertificateChecked: true,
    medicalFormChecked: false,
    medicalFormDate: false,
    insurance: false,
    nitrox: true,
    safety: true
};
var booking = {
    externalRef: false,
    agency: false
};
var equipment = {
    provider: true,
    jacket: false,
    wetsuit: false,
    fins: false,
    altSize: false,
    scubaRegulator: false,
    mask: false,
    flashLight: false,
    computer: false,
    tank: false,
    compass: false
};
exports.DEFAULT_CLUB_SETTINGS_CUSTOMER = {
    general: general,
    diving: diving,
    equipment: equipment,
    booking: booking
};
