"use strict";
exports.__esModule = true;
exports.CLUB_DEFAULT_UI_DIVE_MODE = void 0;
exports.CLUB_DEFAULT_UI_DIVE_MODE = {
    colors: {
        observer: '#999',
        snorkeling: '#739165',
        snorkelingSupervised: '#1c6912',
        'first-dive': '#1acbcb',
        training: '#38a4d5',
        'theoretical-training': '#d57a38',
        supervised: '#215887',
        autonomous: '#222',
        autoSupervised: '#ac0202',
        'free-dive': '#9b225b',
        unknown: '#e88484',
        instructor: '#f1ae0d'
    }
};
