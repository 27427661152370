import { DiveSessionType } from '@mabadive/app-common-model';
import { diveModeFormatter } from './diveModeFormatter.service';

export const diveSessionTypeFormatter = {
  formatType,
};

function formatType(type: DiveSessionType): string {
  switch (type) {
    case 'dive':
      return 'Plongée';
    case 'first-dive':
      return 'Baptêmes';
    case 'snorkeling':
      return diveModeFormatter.formatDiveMode('snorkeling', {
        format: 'short-label',
      });
    case 'snorkeling-tour':
      return diveModeFormatter.formatDiveMode('snorkelingSupervised', {
        format: 'short-label',
      });
    case 'dive-auto-supervised':
      return diveModeFormatter.formatDiveMode('autoSupervised', {
        format: 'short-label',
      });
    case 'boat-tour':
      return 'Tour en bateau';
    case 'theoretical-training':
      return 'Formation théorique';
  }
  return '';
}
