import {
  ClubParticipant,
  ClubPublicSettings,
} from '@mabadive/app-common-model';
import { diveSessionServiceFormatter } from '../session/diveSessionServiceFormatter.service';
import { diveModeFormatter } from './diveModeFormatter.service';

export type DiveTypeFullStringFormat =
  | 'short'
  | 'deep-role'
  | 'divetype-deep-role';

export const diveTypeFullFormatter = {
  formatDiveTypeFull,
};

function formatDiveTypeFull(
  participant: Pick<
    ClubParticipant,
    | 'targetDeep'
    | 'trainingReference'
    | 'certificationReference'
    | 'firstDiveReference'
    | 'diveMode'
  >,
  {
    format,
    clubReference,
    clubPublicSettings,
  }: {
    format: DiveTypeFullStringFormat;
    clubReference: string;
    clubPublicSettings: ClubPublicSettings;
  },
): string {
  if (!participant) {
    return '';
  }

  if (format === 'short') {
    return diveSessionServiceFormatter.formatServiceFromParticipant(
      { diver: undefined, participant },
      { format: 'short-ref', clubReference, clubPublicSettings },
    );
  }
  if (format === 'deep-role') {
    return `${formatTargetDeep(participant)}${formatDiveMode(participant)}`;
  }
  if (format === 'divetype-deep-role') {
    return `${diveSessionServiceFormatter.formatServiceFromParticipant(
      { diver: undefined, participant },
      { format: 'short-ref', clubReference,clubPublicSettings },
    )}${formatTargetDeep(participant)}${
      participant.diveMode ? ` (${formatDiveMode(participant)}` : ''
    }`;
  }
}

function formatTargetDeep(participant: Pick<ClubParticipant, 'targetDeep'>) {
  return participant.targetDeep ? ` (${participant.targetDeep}m)` : '';
}

function formatDiveMode(participant: Pick<ClubParticipant, 'diveMode'>) {
  return participant.diveMode
    ? diveModeFormatter.formatDiveMode(participant.diveMode, {
        format: 'short-label',
      })
    : '';
}
