import { ClubDiverFakeName } from '../../../../app-common-model/src';
import { numberFormatter } from './numberFormatter.service';
import { stringFormatter } from './stringFormatter.service';

export const nameFormatter = {
  formatFullName,
  formatFullNameChunks,
  formatFirstName,
  formatLastName,
};

function formatFullName(
  person: {
    firstName: string;
    lastName: string;
    fakeName?: ClubDiverFakeName;
  },
  { format }: { format: 'lastName-first' | 'firstName-first' } = {
    format: 'lastName-first',
  },
): string {
  const { firstName, lastName } = formatFullNameChunks(person);
  return person
    ? format === 'lastName-first'
      ? `${lastName} ${firstName}`.trim()
      : `${firstName} ${lastName}`.trim()
    : '';
}
function formatFullNameChunks(person: {
  firstName: string;
  lastName: string;
  fakeName?: ClubDiverFakeName;
}): {
  firstName: string;
  lastName: string;
} {
  if (!person) {
    person = {
      firstName: undefined,
      lastName: undefined,
    };
  }
  if (
    !person?.firstName?.trim()?.length &&
    !person?.lastName?.trim()?.length &&
    !!person?.fakeName
  ) {
    person = {
      firstName: buildFakeFirstName(person?.fakeName),
      lastName: person?.fakeName?.baseName,
    };
  }
  return {
    lastName: formatLastName(person),
    firstName: formatFirstName(person),
  };
}

function buildFakeFirstName(fakeName?: ClubDiverFakeName): string {
  if (fakeName?.memberIndex > 0) {
    return `N°${numberFormatter.toFixedIntegerString(
      fakeName?.memberIndex,
      2,
    )}`;
  }
  return '';
}

function formatFirstName(person: { firstName: string }) {
  return person && person.firstName
    ? stringFormatter.toUpperCaseFirstEveryWord(person.firstName)
    : '';
}

function formatLastName(person: { lastName: string }): string {
  return person && person.lastName ? person.lastName.toUpperCase() : '';
}
