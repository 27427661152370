import {
  ClubPlanningLightSessionDef,
  StaffMemberResumeSessionsDayModelSessionDetails,
} from '@mabadive/app-common-model';

export const diveSessionFilter = {
  filterSessions,
  filterStaffSessionsDetails,
};

function filterSessions(
  sessions: ClubPlanningLightSessionDef[],
  {
    isPast,
    filterPastAndEmpty,
    filterCancelled,
  }: {
    isPast: boolean;
    filterPastAndEmpty: boolean;
    filterCancelled: boolean;
  },
): ClubPlanningLightSessionDef[] {
  return (sessions ?? []).filter((session) => {
    if (filterPastAndEmpty) {
      !isPast || // on n'affiche pas les sessions passées sans participant
        session.participants.filter(
          (x) => x.bookingState?.value !== 'cancelled',
        ).length > 0;
    }
    if (filterCancelled) {
      return session.status !== 'cancelled'; // on n'affiche pas les sessions annulées
    }
    return true;
  });
}
function filterStaffSessionsDetails(
  sessions: StaffMemberResumeSessionsDayModelSessionDetails[],
  {
    isPast,
    filterPastAndEmpty,
    filterCancelled,
  }: {
    isPast: boolean;
    filterPastAndEmpty: boolean;
    filterCancelled: boolean;
  },
): StaffMemberResumeSessionsDayModelSessionDetails[] {
  return (sessions ?? []).filter(({ session }) => {
    if (filterPastAndEmpty) {
      const isOk =
        !isPast || // on n'affiche pas les sessions passées sans participant
        session.participants.filter(
          (x) => x.bookingState?.value !== 'cancelled',
        ).length > 0;
      if (!isOk) {
        return false;
      }
    }
    if (filterCancelled) {
      const isOk = session.status !== 'cancelled'; // on n'affiche pas les sessions annulées
      if (!isOk) {
        return false;
      }
    }
    return true;
  });
}
