import {
  Booking,
  BookingCustomerCheckoutState,
  BookingCustomerPayment,
  BookingCustomerUpdate,
  BookingCustomerUpdateStatus,
} from '@mabadive/app-common-model';

export const checkoutStateBuilder = {
  buildCheckoutState,
};
function buildCheckoutState({
  booking,
  bookingCustomerUpdates,
  bookingPayments,
}: {
  booking: Booking;
  bookingCustomerUpdates: BookingCustomerUpdate[];
  bookingPayments: BookingCustomerPayment[];
}): BookingCustomerCheckoutState {
  const enableCustomerUpdate =
    booking?.bookingCustomerConfig?.customerUi?.enableCustomerUpdate;

  const pendingList = booking?.bookingCustomerConfig?.pendingList;

  const pendingListCount = [
    pendingList?.diversContactInfo,
    pendingList?.diversDivingInfo,
    pendingList?.diversExpectedDiveInfo,
    pendingList?.diversPostalAddressInfo,
  ].filter((x) => !!x).length;

  const customerUpdateStatus: BookingCustomerUpdateStatus =
    !enableCustomerUpdate || pendingListCount === 0
      ? 'ok'
      : bookingCustomerUpdates.find((x) => x.status === 'draft') !== undefined
      ? 'draft'
      : bookingCustomerUpdates.find((x) => x.status === 'to-validate') !==
        undefined
      ? 'to-validate'
      : 'ko';

  const pendingOnlinePayments: BookingCustomerPayment[] =
    bookingPayments.filter(
      (x) => !!x.onlinePlatformId && x.paymentState === 'pending',
    );

  const paymentsState: {
    enableOnlinePayment: boolean;
    pendingOnlinePayments: BookingCustomerPayment[];
  } = {
    pendingOnlinePayments,
    enableOnlinePayment: pendingOnlinePayments.length > 0,
  };

  const checkoutState: BookingCustomerCheckoutState = {
    customerUpdateState: {
      pendingList,
      pendingListCount,
      status: customerUpdateStatus,
      // enableUpdate: tant que ça n'a pas été accepté, on autorise à faire de nouvelles modifications (obligatoire maintenant qu'il y a des liens individuels)
      enableUpdate:
        booking.bookingStatus !== 'draft' &&
        (customerUpdateStatus === 'draft' ||
          customerUpdateStatus === 'to-validate' ||
          customerUpdateStatus === 'ko'),
    },
    paymentsState,
  };
  return checkoutState;
}
