import { ClubSettingsPublic } from '@mabadive/app-common-model';
import { stringFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import { default as React, useMemo } from 'react';
import { useDiveCenterHomePageLocalState } from '../../../../../dive-center/DiveCenterHomePage/useDiveCenterHomePageLocalState.hook';
import { appWebConfig } from '../../../root/config';
import { useMediaSizeTailwind } from '../../services';

export const Customer_TopBarDiveCenter = ({
  clubSettingsPublic,
  className,
}: {
  clubSettingsPublic: ClubSettingsPublic;
  className?: string;
}) => {
  const mediaSize = useMediaSizeTailwind();

  const localState = useDiveCenterHomePageLocalState({
    clubPublicReference: clubSettingsPublic?.clubPublicReference,
  });
  const { loadableContent, loadedContent } = localState;

  const clubSettings = loadedContent?.clubResume?.clubSettings;
  const diveCenters = loadedContent?.clubResume?.diveCenters;

  const clubPublicData = clubSettings?.publicData;
  const diveCenter = diveCenters?.length ? diveCenters[0] : undefined;
  const diveCenterPublicData = diveCenter?.publicData;

  const logoSquareUrl = useMemo(
    () =>
      stringFormatter.buildCDNUrl(
        appWebConfig.applications.cdnUrl,
        clubPublicData?.brand?.logoSquareUrl,
      ),
    [clubPublicData?.brand?.logoSquareUrl],
  );

  return loadableContent.contentState !== 'full' ? null : (
    <div className={clsx('flex gap-2 sm:gap-3 md:gap-4', className)}>
      {logoSquareUrl && (
        <img
          src={logoSquareUrl}
          className={'max-h-[40px] max-w-[35vw] sm:max-w-50vw'}
          alt="Logo"
        />
      )}

      <div className="">
        {diveCenterPublicData?.brand?.name && (
          <div className="flex gap-1 sm:gap-2 text-sm items-end font-bold text-gray-600">
            <div className="uppercase whitespace-nowrap">
              {diveCenterPublicData?.brand?.name}
            </div>{' '}
            {diveCenterPublicData?.brand?.locationResume && (
              <span className="hidden md:inline text-xs uppercase whitespace-nowrap">
                {diveCenterPublicData?.brand?.locationResume}
              </span>
            )}
          </div>
        )}
        {appWebConfig.debug.showEnvId ||
        appWebConfig.debug.showAppVersion ||
        appWebConfig.debug.showMediaSize ? (
          <div className="flex gap-1 sm:gap-2 font-bold text-app-xxs text-white">
            {appWebConfig.debug.showEnvId && appWebConfig.envId ? (
              <span className="bg-red-600 px-1">
                {appWebConfig.envId.toUpperCase()}
              </span>
            ) : null}
            {appWebConfig.debug.showAppVersion && appWebConfig.appVersion ? (
              <span className="bg-gray-600 px-1">
                {appWebConfig.appVersion.toLowerCase()}
              </span>
            ) : null}
            {appWebConfig.debug.showMediaSize && mediaSize ? (
              <span className="bg-gray-600 px-1">
                {mediaSize.toUpperCase()}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};
