import {
  ClubPublicData,
  ClubPublicSettings,
  DiveCenterPublicData,
} from '@mabadive/app-common-model';
import { stringFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import {
  AppButtonFreeColor,
  AppHeroIcons,
  AppIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { useRedirect } from '../../../_core/data/hooks';
import { diveCenterBookingUrlBuilder } from '../../BO-booking/diveCenterBookingUrlBuilder.service';
import { CustomerProductsPage_ClubLegalInformationCard } from '../../DiveCenterEcommerceHomePage/components/02.cards/CustomerProductsPage_ClubLegalInformationCard';
import { DiveCenterBrandCardContactLinks } from './DiveCenterBrandCardContactLinks';
import { DiveCenterBrandSocialIcons } from './DiveCenterBrandSocialIcons';

export function DiveCenterBrandCard({
  clubPublicReference,
  clubPublicData,
  clubPublicSettings,
  diveCenterPublicData,
  showHeader = true,
  showContactLinks = true,
  showLegalInformation = true,
  showActivities = false,
  className = '',
}: {
  clubPublicReference: string;
  clubPublicData: ClubPublicData;
  clubPublicSettings: ClubPublicSettings;
  diveCenterPublicData: DiveCenterPublicData;
  showHeader?: boolean;
  showContactLinks?: boolean;
  showLegalInformation?: boolean;
  showActivities?: boolean;
  className?: string;
}) {
  const redirectTo = useRedirect();
  const logoSquareUrl = useMemo(
    () =>
      stringFormatter.buildCDNUrl(
        appWebConfig.applications.cdnUrl,
        clubPublicData?.brand?.logoSquareUrl,
      ),
    [clubPublicData?.brand?.logoSquareUrl],
  );
  const appUiTheme = clubPublicSettings?.appUiTheme;

  const showCatalogButtonFromHome =
    clubPublicSettings?.customerSpace?.onlineBooking?.enableCatalog &&
    clubPublicSettings?.customerSpace?.onlineBooking?.showCatalogButtonFromHome;

  const [showLegalInformationDetails, setShowLegalInformationDetails] =
    useState(false);

  return !(clubPublicData && diveCenterPublicData) ? null : (
    <div className={clsx(className)}>
      <div
        className={
          'flex flex-col xs:flex-row xs:items-start xs:justify-between gap-4 text-gray-800'
        }
      >
        {showHeader && (
          <div className="flex gap-3 xs:gap-4">
            <div className={'text-center'}>
              {logoSquareUrl ? (
                <img
                  src={logoSquareUrl}
                  className={'inline max-h-[80px] max-w-[80px]'}
                  alt="logo"
                />
              ) : (
                <AppIcons.ui.emptyPhoto
                  className={'inline max-h-[80px] max-w-[80px]'}
                  title="Votre logo ici"
                />
              )}
            </div>
            <div className={'text-left'}>
              <div className="text-lg font-bold leading-4 uppercase">
                {diveCenterPublicData?.brand?.name}
              </div>
              <div className="text-base leading-normal uppercase text-gray-600">
                {diveCenterPublicData?.brand?.locationResume}
              </div>
              <DiveCenterBrandSocialIcons
                className="mt-1"
                publicData={clubPublicData}
              />
            </div>
          </div>
        )}
        {showContactLinks && (
          <div className="grid gap-1">
            <div className="grid gap-2">
              <DiveCenterBrandCardContactLinks
                contact={diveCenterPublicData?.contact}
                clubPublicSettings={clubPublicSettings}
              />
              {(diveCenterPublicData.contacts ?? []).map(
                (contact, contactIndex) => (
                  <DiveCenterBrandCardContactLinks
                    key={contactIndex}
                    contact={contact}
                    clubPublicSettings={clubPublicSettings}
                  />
                ),
              )}
            </div>
            {showLegalInformation &&
              clubPublicData?.legalInformation?.legalForm && (
                <div
                  onClick={() =>
                    setShowLegalInformationDetails(!showLegalInformationDetails)
                  }
                >
                  {!showLegalInformationDetails && (
                    <div className="text-gray-400 cursor-pointer text-left">
                      <AppHeroIcons.eye className="inline-block w-4 h-4 mr-1" />{' '}
                      informations légales
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
        {showActivities && showCatalogButtonFromHome && (
          <AppButtonFreeColor
            className="text-sm whitespace-nowrap"
            icon={EntitiesIcons.booking}
            onClick={(e) => {
              e.stopPropagation();
              redirectTo(
                diveCenterBookingUrlBuilder.buildDiveCenterEcommerceHomeUrl({
                  clubPublicReference,
                }),
              );
            }}
            {...appUiTheme.buttonPrimary}
          >
            Découvrir nos activités
          </AppButtonFreeColor>
        )}
      </div>
      {showLegalInformation && clubPublicData?.legalInformation?.legalForm && (
        <div
          className="mt-4"
          onClick={() =>
            setShowLegalInformationDetails(!showLegalInformationDetails)
          }
        >
          {showLegalInformationDetails ? (
            <CustomerProductsPage_ClubLegalInformationCard
              legalInformation={clubPublicData?.legalInformation}
            />
          ) : (
            <div
              className={clsx(
                'text-gray-400 cursor-pointer',
                showContactLinks && 'hidden',
              )}
            >
              <AppHeroIcons.eye className="inline-block w-4 h-4 mr-1" />{' '}
              Afficher les informations légales
            </div>
          )}
        </div>
      )}
    </div>
  );
}
