import { DiveMode, DiveSessionGroupDiveMode } from '@mabadive/app-common-model';

export const diveSessionGroupDiveModeBuilder = {
  buildFromParticipantsDiveModes,
};

const PRIORITY_DIVE_MODE: DiveMode[] = [
  'first-dive',
  'training',
  'supervised',
  'autonomous',
];

function buildFromParticipantsDiveModes({
  diveModes,
  hasDiveGuide,
  hasInstructor,
  debugContext,
}: {
  diveModes: DiveMode[];
  hasInstructor: boolean;
  hasDiveGuide: boolean;
  debugContext?: string;
}): DiveSessionGroupDiveMode {
  const diveMode = diveModes.reduce((acc, diveMode) => {
    if (!acc) {
      return diveMode;
    }
    if (acc !== diveMode) {
      const priorityDiveMode = PRIORITY_DIVE_MODE.find(
        (priorityDiveMode) =>
          acc === priorityDiveMode || diveMode === priorityDiveMode,
      );
      if (priorityDiveMode) {
        return priorityDiveMode;
      }
    }
    return acc;
  }, undefined);
  if (hasInstructor && diveMode === 'autonomous') {
    return 'supervised';
  }

  if (
    hasDiveGuide &&
    (diveMode === 'supervised' ||
      diveMode === 'training' ||
      diveMode === 'first-dive')
  ) {
    return 'autonomous';
  }
  return diveMode as DiveSessionGroupDiveMode;
}
