/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import {
  AppBreadcrumbBar,
  AppButton,
  AppIconsUI,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { AppMiniProgressBar } from 'src/business/dive-center/components';
import { DiveCenterBookingMembersList } from '../../../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList';
import { BookingResumeMembersTableOnClickMemberAction } from '../../../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList/DiveCenterBookingMembersListItem';
import { DiveCenterBookingCheckoutPageLocalState } from '../../useDiveCenterBookingCheckoutPageLocalState.hook';
import { BookingCheckoutEditMembersPanelNavbar } from './BookingCheckoutEditMembersPanelNavbar';
import { BookingCheckoutEditMembersPanelNextOrFinishButton } from './BookingCheckoutEditMembersPanelNextOrFinishButton';
import { BookingCheckoutEditMembersPanelPreviousOrCancelButton } from './BookingCheckoutEditMembersPanelPreviousOrCancelButton';
import {
  DiveCenterBookingMemberEditFormContact,
  DiveCenterBookingMemberEditFormDiveService,
  DiveCenterBookingMemberEditFormInsurance,
  DiveCenterBookingMemberEditFormNewsletter,
} from './form';
import {
  DiveCenterBookingMemberEditPanelLocalState,
  useDiveCenterBookingMemberEditPanelLocalState,
} from './useDiveCenterBookingMemberEditPanelLocalState';
export const BookingCheckoutEditMembersPanel: FC<{
  localState: DiveCenterBookingCheckoutPageLocalState;
}> = ({ localState: globalState }) => {
  const localState: DiveCenterBookingMemberEditPanelLocalState =
    useDiveCenterBookingMemberEditPanelLocalState({
      globalState,
    });

  const {
    breadcrumbItems,
    viewState,
    aggregatedBookingResume,
    form,
    clubSettings,
    actions,
    editableParticipantsCount,
    booking,
    insuranceEnabled,
    newsletterSettings,
  } = localState;

  return (
    <form
      className="w-full"
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        actions.nextParticipant();
        return false;
      }}
    >
      <AppPageContainerWithFixedBars
        marginBottom={true}
        paddingBottom={true}
        contentClassName="bg-gray-50"
        headerBar={() => (
          <>
            <AppBreadcrumbBar color={'#333'} items={breadcrumbItems} />
            <div className="w-full text-center text-gray-600 font-bold text-base uppercase">
              <div className="2xl:grid 2xl:grid-cols-4">
                <BookingCheckoutEditMembersPanelNavbar
                  localState={localState}
                  className="my-2 2xl:col-span-3 "
                />
              </div>
              {editableParticipantsCount > 1 && (
                <AppMiniProgressBar
                  progress={
                    (viewState.participantsIndex + 1) /
                    editableParticipantsCount
                  }
                />
              )}
            </div>
          </>
        )}
        // headerBar={() => (
        //   <AppButtonsBar hasChanges={true}>
        //     <>
        //       <AppButton
        //         type="button"
        //         className="uppercase font-bold"
        //         size="normal"
        //         icon={AppHeroIcons.actionCancel}
        //         tabIndex={500}
        //         color="gray-outline-light"
        //         onClick={backOrCancel}
        //       >
        //         Annuler
        //       </AppButton>
        //       <AppButton
        //         type="button"
        //         className="uppercase font-bold"
        //         disabled={!formState.isValid}
        //         size="normal"
        //         icon={AppHeroIcons.actionSave}
        //         iconPosition="right"
        //         color="primary-outline-light"
        //         onClick={() => {
        //           submitForm();
        //         }}
        //       >
        //         Confirmer
        //       </AppButton>
        //       <input type="submit" className="hidden" />
        //     </>
        //   </AppButtonsBar>
        // )}
      >
        <div className="2xl:grid 2xl:grid-cols-4 gap-4 items-start">
          <div className="2xl:col-span-3 app-card app-p-content grid gap-4">
            <div className="grid lg:grid-cols-2 gap-2 overflow-auto">
              {/* <div>
                <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                  Questionnaire médical
                </h3>
                <DiveCenterBookingMemberEditFormMedical
                  key={viewState.participantsIndex}
                  className="px-4 my-5"
                  clubSettings={clubSettings}
                  participantsIndex={viewState.participantsIndex}
                  booking={booking}
                />
              </div> */}
              <div>
                <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                  Coordonnées
                </h3>
                <DiveCenterBookingMemberEditFormContact
                  key={viewState.participantsIndex}
                  className="px-4 my-5"
                  form={form}
                  clubSettings={clubSettings}
                  participantsIndex={viewState.participantsIndex}
                  booking={booking}
                  onLanguageCodeUpdated={
                    localState.actions.onLanguageCodeUpdated
                  }
                />
              </div>
              <div>
                <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                  Prestation
                </h3>
                <DiveCenterBookingMemberEditFormDiveService
                  key={viewState.participantsIndex}
                  className="px-4 my-5"
                  form={form}
                  clubSettings={clubSettings}
                  participantsIndex={viewState.participantsIndex}
                  booking={booking}
                />
              </div>
              {insuranceEnabled && (
                <div>
                  <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                    Assurance
                  </h3>
                  <DiveCenterBookingMemberEditFormInsurance
                    key={viewState.participantsIndex}
                    className="px-4 my-5"
                    form={form}
                    clubSettings={clubSettings}
                    participantsIndex={viewState.participantsIndex}
                    booking={booking}
                  />
                </div>
              )}
              {newsletterSettings?.enabled && (
                <div>
                  <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                    Newsletter
                  </h3>
                  <DiveCenterBookingMemberEditFormNewsletter
                    key={viewState.participantsIndex}
                    className="px-4 my-5"
                    form={form}
                    clubSettings={clubSettings}
                    participantsIndex={viewState.participantsIndex}
                    booking={booking}
                  />
                </div>
              )}
            </div>
            <div className="flex-grow"></div>
            <div className={'flex justify-center gap-4 lg:gap-8'}>
              <BookingCheckoutEditMembersPanelPreviousOrCancelButton
                localState={localState}
              />
              <BookingCheckoutEditMembersPanelNextOrFinishButton
                localState={localState}
              />
            </div>
          </div>
          <div className="hidden 2xl:grid app-card app-p-content gap-2 items-start">
            {localState.az?.view?.otherParticipantsDetails && (
              <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                Liste des participants
              </h3>
            )}
            <div className="grid gap-4 my-4">
              <AppButton
                type="button"
                className={'uppercase font-bold'}
                disabled={!form.formState.isValid}
                size="normal"
                icon={AppIconsUI.navLeft}
                color="primary-outline-light"
                onClick={() => {
                  actions.backToPreviousStep();
                }}
              >
                Retour
              </AppButton>
              <AppButton
                type="button"
                className="uppercase font-bold justify-end"
                disabled={!form.formState.isValid}
                size="normal"
                icon={AppIconsUI.navRight}
                iconPosition="right"
                color={
                  viewState.participantsIndex + 1 < editableParticipantsCount
                    ? 'primary-outline-light'
                    : 'primary-bg'
                }
                onClick={() => {
                  actions.goNextStep();
                }}
              >
                Terminer
              </AppButton>
            </div>
            <DiveCenterBookingMembersList
              enableCustomerUpdate={true}
              aggregatedBookingResume={aggregatedBookingResume}
              onClick={(
                bookingMembersFull: CUS_BookingMemberFull,
                options: {
                  action: BookingResumeMembersTableOnClickMemberAction;
                  participantsIndex: number;
                },
              ) => {
                actions.editParticipantByIndex(options.participantsIndex);
              }}
            />
            <div className="grid gap-4 my-4">
              <AppButton
                type="button"
                className={'uppercase font-bold'}
                disabled={!form.formState.isValid}
                size="normal"
                icon={AppIconsUI.navLeft}
                color="primary-outline-light"
                onClick={() => {
                  actions.backToPreviousStep();
                }}
              >
                Retour
              </AppButton>
              <AppButton
                type="button"
                className="uppercase font-bold justify-end"
                disabled={!form.formState.isValid}
                size="normal"
                icon={AppIconsUI.navRight}
                iconPosition="right"
                color={
                  viewState.participantsIndex + 1 < editableParticipantsCount
                    ? 'primary-outline-light'
                    : 'primary-bg'
                }
                onClick={() => {
                  actions.goNextStep();
                }}
              >
                Terminer
              </AppButton>
            </div>
          </div>
        </div>
      </AppPageContainerWithFixedBars>
    </form>
  );
};
