/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettings } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { COUNTRY_OPTIONS } from 'src/business/dive-center/components';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormAddress = ({
  form,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  participantsIndex: number;
  clubSettings: ClubSettings;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const baseFormName = `participants.${participantsIndex}` as const;

  const { control, setValue } = form;

  return (
    <div
      className={`w-full flex flex-col sm:col-span-2 md:col-span-4 sm:grid sm:grid-cols-4 gap-4 ${className}`}
    >
      <AppFormControlRHF_Deprecated
        className={'sm:col-span-2 sm:col-span-4 w-full'}
        label="Adresse postale"
        control={control}
        name={`${baseFormName}.diver.homeAddress.line1` as const}
        renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
      />
      <div className={'sm:col-span-2 w-full flex flex-row gap-4'}>
        <AppFormControlRHF_Deprecated
          label="Code postal"
          control={control}
          name={`${baseFormName}.diver.homeAddress.postalCode` as const}
          renderComponent={(props) => (
            <AppInputRHF {...props} className={'w-24'} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Ville"
          control={control}
          name={`${baseFormName}.diver.homeAddress.city` as const}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
      </div>
      <div className={'sm:col-span-2 w-full flex flex-row gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="État"
          control={control}
          name={`${baseFormName}.diver.homeAddress.state` as const}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Pays"
          control={control}
          name={`${baseFormName}.diver.homeAddress.country` as const}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={COUNTRY_OPTIONS}
              fullWidth
            />
          )}
        />
      </div>
    </div>
  );
};
