"use strict";
// ISO 3166-1 codes - https://github.com/umpirsky/country-list
exports.__esModule = true;
exports.CUSTOMER_ORIGIN_COMMON_VALUES = void 0;
exports.CUSTOMER_ORIGIN_COMMON_VALUES = [
    'Internet',
    'Facebook',
    'Instagram',
    'Tripadvisor',
    'Office du tourisme',
    'Agence de voyage',
    'Moteur de recherche',
    'Guide tourstique',
    'Publicité',
    'Salon de la plongée',
    'Partenaire',
    'Bouche à oreille',
    'Déjà venu',
    'Magasin',
];
