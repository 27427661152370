import {
  APP_DIVE_MODE_COLORS,
  ClubSettings,
  DiveMode,
} from '@mabadive/app-common-model';

export const diveModeColorClassBuilder = { getDiveModeColor };

function getDiveModeColor(
  diveMode: DiveMode | 'ungroupped',
  { clubSettings }: { clubSettings: ClubSettings },
): string {
  return (
    clubSettings?.ui?.diveMode?.colors[diveMode] ??
    APP_DIVE_MODE_COLORS[diveMode]
  );
}
