/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Booking,
  Customer_ClubSettingsPublicCustomer,
  DIVE_CERTIFICATIONS,
  DiveCertificationReference,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { DIVE_SERVICE_ORGANIZATIONS_OPTIONS } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/DIVE_SERVICE_ORGANIZATIONS_OPTIONS.const';
import { NITROX_CERTIFICATION_OPTIONS } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/NITROX_CERTIFICATION_OPTIONS.const';
import { mainCertificationOptionBuilder } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/mainCertificationOptionBuilder.service';
import { YES_NO_OPTIONS } from 'src/business/dive-center/components';
import {
  AppInputBooleanRHF,
  AppInputRHF,
  AppToogleButtonGroupRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormDiveService = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const baseFormName = `participants.${participantsIndex}` as const;

  const [hasCertification, certificationReference] = useWatch({
    control,
    name: [
      `${baseFormName}.bookingMember.details.hasCertification` as const,
      `${baseFormName}.diver.mainCertificationReference` as const,
    ],
  });
  const mainCertification = useMemo(
    () =>
      DIVE_CERTIFICATIONS.find((x) => x.reference === certificationReference),
    [certificationReference],
  );
  const orgGroup = mainCertification?.organizationv2;
  const pendingList = booking.bookingCustomerConfig?.pendingList;
  const displayFields =
    clubSettings.publicSettings?.customerSpace?.displayFields;

  const diveModesOptions: ValueLabel<DiveMode>[] = useMemo(
    () =>
      (
        displayFields?.diversExpectedDiveInfo
          ?.expectedDiveModesWithCertification ?? []
      )
        .filter((x) => !!x)
        .map((diveMode) => ({
          value: diveMode,
          label: diveModeFormatter.formatDiveMode(diveMode, {
            format: 'long-label',
          }),
        })),
    [displayFields?.diversExpectedDiveInfo?.expectedDiveModesWithCertification],
  );

  const diveModesOptionsBeginner: ValueLabel<DiveMode>[] = useMemo(
    () =>
      (displayFields?.diversExpectedDiveInfo?.expectedDiveModesBeginner ?? [])
        .filter((x) => !!x)
        .map((diveMode) => ({
          value: diveMode,
          label: diveModeFormatter.formatDiveMode(diveMode, {
            format: 'long-label',
          }),
        })),
    [displayFields?.diversExpectedDiveInfo?.expectedDiveModesBeginner],
  );

  const mainCertificationOptions = useMemo(
    () =>
      mainCertificationOptionBuilder.buildOptions({
        // orgGroupReferencev2: orgGroup,
      }),
    [],
  );

  return (
    <div className={`w-full max-w-[97vw] flex flex-col ${className}`}>
      {pendingList?.diversDivingInfo && (
        <>
          <div className={'w-full sm:max-w-[24rem]'}>
            <AppFormControlRHF_Deprecated
              className={'md:col-span-4 w-full'}
              label="Ce participant a-t-il un niveau de plongée"
              control={control}
              name={
                `${baseFormName}.bookingMember.details.hasCertification` as const
              }
              renderComponent={(props) => (
                <AppToogleButtonGroupRHF
                  {...props}
                  color="boolean"
                  theme="strong"
                  items={YES_NO_OPTIONS}
                  // selected={selected}
                  // onChanges={onChange}
                />
              )}
            />
          </div>
          {hasCertification && (
            <>
              <div
                className={
                  'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                <div className={'md:col-span-2 w-full flex gap-4'}>
                  <AppFormControlRHF_Deprecated
                    className={'w-full'}
                    label={'Niveau / certification'}
                    control={control}
                    name={
                      `${baseFormName}.diver.divingCertification1.mainCertificationReference` as const
                    }
                    required={false}
                    renderComponent={(props) => (
                      <AppSingleAutocomplete2RHF
                        optionClassName="text-xs sm:text-sm"
                        {...props}
                        options={mainCertificationOptions}
                        onChange={(value: DiveCertificationReference) => {
                          setValue(
                            `${baseFormName}.diver.mainCertificationReference` as const,
                            value,
                          );
                        }}
                      />
                    )}
                  />
                  {orgGroup?.orgGroupReferencev2 === 'international' && (
                    <AppFormControlRHF_Deprecated
                      label={'Deep Diver'}
                      control={control}
                      name={
                        `${baseFormName}.diver.divingCertification1.specialties.deepDiver` as const
                      }
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          className="scale-125 block mx-auto mt-2"
                          {...props}
                          type="checkbox"
                        />
                      )}
                    />
                  )}
                </div>
                <AppFormControlRHF_Deprecated
                  className={'md:col-span-2 w-full flex gap-4'}
                  label="Nitrox"
                  control={control}
                  name={
                    `${baseFormName}.diver.divingCertification1.gazCertificationReference` as const
                  }
                  required={false}
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      className={'w-full'}
                      theme="material-ui"
                      color="theme"
                      {...props}
                      options={NITROX_CERTIFICATION_OPTIONS}
                    />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className={'md:col-span-2 w-full flex gap-4'}
                  label={'Organisme'}
                  control={control}
                  name={
                    `${baseFormName}.diver.divingCertification1.orgReference` as const
                  }
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      className={'w-full'}
                      theme="material-ui"
                      color="theme"
                      {...props}
                      options={DIVE_SERVICE_ORGANIZATIONS_OPTIONS}
                      onChange={(e) => {
                        // onChangeOrgReference(e)
                      }}
                    />
                  )}
                />
              </div>
              <div
                className={
                  'mt-2 w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                <AppFormControlRHF_Deprecated
                  className="md:col-span-2 w-full"
                  label="Date de la dernière plongée (approx.)"
                  control={control}
                  name={
                    `${baseFormName}.bookingMember.details.lastDiveDate` as const
                  }
                  renderComponent={(props) => (
                    <AppInputDatePickerMaterialRHF
                      className="w-full"
                      {...props}
                    />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className="md:col-span-2 w-full"
                  label="Nombre de plongées effectuées (approx.)"
                  control={control}
                  name={
                    `${baseFormName}.bookingMember.details.totalDivesCount` as const
                  }
                  renderComponent={(props) => (
                    <AppInputRHF className="w-full" type="number" {...props} />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className="md:col-span-2 w-full"
                  label="Date du certificat médical"
                  control={control}
                  name={`${baseFormName}.diver.medicalCertificateDate` as const}
                  renderComponent={(props) => (
                    <AppInputDatePickerMaterialRHF
                      className="w-full"
                      {...props}
                    />
                  )}
                />
              </div>
              {pendingList?.diversExpectedDiveInfo &&
                diveModesOptions?.length > 0 && (
                  <AppFormControlRHF_Deprecated
                    className="md:col-span-2 w-full"
                    label="Prestation souhaitée"
                    control={control}
                    name={
                      `${baseFormName}.bookingMember.details.expectedDive.diveMode` as const
                    }
                    required={false}
                    renderComponent={(props) => (
                      <AppSingleAutocomplete2RHF
                        {...props}
                        options={diveModesOptions}
                        onChange={(diveMode: DiveMode) => {
                          // onDiveModeChange(diveMode);
                        }}
                      />
                    )}
                  />
                )}
            </>
          )}
        </>
      )}

      {pendingList?.diversExpectedDiveInfo &&
        hasCertification === false &&
        diveModesOptionsBeginner?.length > 0 && (
          <div className={'mt-2 w-full sm:max-w-[36rem]'}>
            <AppFormControlRHF_Deprecated
              label="Prestation souhaitée"
              control={control}
              name={
                `${baseFormName}.bookingMember.details.expectedDive.diveMode` as const
              }
              required={false}
              renderComponent={(props) => (
                <AppToogleButtonGroupRHF
                  {...props}
                  items={diveModesOptionsBeginner}
                  color="dive-mode"
                  theme="strong"
                  // onChange={(diveMode: DiveMode) => {
                  //   // onDiveModeChange(diveMode);
                  // }}
                />
              )}
            />
          </div>
        )}
      {displayFields?.diversExpectedDiveInfo?.comment?.edit && (
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Commentaire"
          control={control}
          name={
            `${baseFormName}.bookingMember.details.expectedDive.customerComment` as const
          }
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      )}
    </div>
  );
};
