"use strict";
exports.__esModule = true;
exports.DEFAULT_PROFILE_BOOKING = void 0;
exports.DEFAULT_PROFILE_BOOKING = {
    label: 'Inscriptions & paiements',
    authorizations: {
        // NOUVELLE HIERARCHIE: inspirée de anim-live AuthUserCompanyAuthorizations
        staff: {
            view: {
            // staffPlanning: false,
            // staffSettingsDetails: false,
            // staffSettingsList: false,
            },
            action: {
                // editStaffSheet: false,
                // editPlanningDefault: false,
                // editPlanningDaily: false,
                editPlanningSession: true
            }
        },
        creditNote: {
            // avoirs
            view: {
                bookingDetails: true,
                paymentsList: true
            },
            action: {
                create: true,
                edit: true,
                "delete": true
            }
        },
        communication: {
            action: {
                sendSessionMessage: true,
                sendSessionMessageEmail: true,
                sendSessionMessageSms: true,
                sendSessionMessageWhatsApp: true,
                sendBookingMessage: true,
                sendBookingMessageEmail: true,
                sendBookingMessageSms: true,
                sendBookingMessageWhatsApp: true
            }
        },
        // DEPRECATED: ancienne hiérarchie
        view: {
            planning: {
                participant: true,
                event: true
            },
            participant: {
                diver: true,
                history: true,
                booking: true,
                billing: true,
                payment: true
            },
            dashboard: {
                dailyCash: true,
                dailyPlanningResume: true,
                dailyStats: true,
                stats: false
            },
            lists: {
                diver: true,
                participant: true,
                billing: true,
                payment: true
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                mabadiveBilling: false
            }
        },
        edit: {
            participant: {
                diver: true,
                booking: true,
                billing: true,
                billingCreate: true,
                billingEdit: true,
                billingDelete: true,
                payment: true,
                paymentCreate: true,
                paymentDelete: true,
                paymentEdit: true,
                paymentOnlineCapture: false
            },
            planning: {
                session: true,
                event: true,
                securitySheet: true
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                accountProfiles: false,
                general: false,
                onlinePayment: false,
                accountProfilesImpersonate: false,
                archiveData: false,
                accomodation: false,
                equipment: false,
                customerSpace: false,
                exportDaily: false,
                inquiry: false
            }
        },
        download: {
            dailyCash: false,
            securitySheet: true,
            dailyResume: true,
            bookings: false,
            divers: false
        },
        multiDiveCenters: {
            switchCenter: false,
            view: {
                planning: false,
                participant: false,
                dashboard: false,
                lists: false,
                settings: false
            },
            edit: {
                participant: false,
                planning: false,
                settings: false
            }
        }
    }
};
