import { AppEntityUpdatePatch, ClubDiver } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageUpdateState,
} from '../../DiveCenterBookingPage/model';
import { bookingParticipantEditorDialogChangesDiverParser } from '../panels/BookingCheckoutPanel2EditMembers/changes-builder';
import { DiveCenterBookingMemberEditFormModel } from '../panels/BookingCheckoutPanel2EditMembers/model';

export const bookingCheckoutFormUpdateBuilder = {
  buildChanges,
};
function buildChanges({
  formValue,
  updateState: initialUpdateState,
  aggregatedBookingResume,
  initialFormValue,
}: {
  formValue: DiveCenterBookingMemberEditFormModel;
  updateState: DiveCenterBookingPageUpdateState;
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  initialFormValue: DiveCenterBookingMemberEditFormModel;
}): DiveCenterBookingPageUpdateState {
  const { diverPatches, membersPatches } = (
    aggregatedBookingResume?.bookingMembersFull ?? []
  ).reduce(
    (acc, existingBookingMemberFull, i) => {
      const pInitial = initialFormValue.participants[i];
      const p = formValue.participants[i];
      {
        const initialDiver = {
          ...existingBookingMemberFull.diver,
          ...bookingParticipantEditorDialogChangesDiverParser.parseFormValueDiver(
            pInitial,
          ),
        };
        const finalDiver: ClubDiver = {
          ...initialDiver,
          ...bookingParticipantEditorDialogChangesDiverParser.parseFormValueDiver(
            p,
          ),
        };
        const patchOperations = jsonPatcher.compareObjects(
          initialDiver,
          finalDiver,
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: false,
          },
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: initialDiver._id,
            patchOperations,
          };
          acc.diverPatches.push(patch);
        }
      }
      {
        // const initialMember = existingBookingMemberFull.bookingMember;
        const initialMember = {
          ...existingBookingMemberFull.bookingMember,
          ...pInitial.bookingMember,
        };
        const patchOperations = jsonPatcher.compareObjects(
          initialMember,
          {
            ...initialMember,
            ...p.bookingMember,
          },
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: false,
          },
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: initialMember._id,
            patchOperations,
          };
          acc.membersPatches.push(patch);
        }
      }
      return acc;
    },
    {
      diverPatches: [],
      membersPatches: [],
    } as {
      diverPatches: AppEntityUpdatePatch[];
      membersPatches: AppEntityUpdatePatch[];
    },
  );

  if (diverPatches.length || membersPatches.length) {
    const updateStateLocal: DiveCenterBookingPageUpdateState = {
      ...initialUpdateState,
    };

    updateStateLocal.diversChanges = changeDescriptorManager.updateMany(
      diverPatches,
      {
        changeDescriptors: updateStateLocal.diversChanges,
      },
    );
    updateStateLocal.bookingMembersChanges = changeDescriptorManager.updateMany(
      membersPatches,
      {
        changeDescriptors: updateStateLocal.bookingMembersChanges,
      },
    );
    return updateStateLocal;
  }
  return initialUpdateState;
}
