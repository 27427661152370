import { Disclosure, Transition } from '@headlessui/react';
import React, { FC } from 'react';
import { AppHeroIcons } from '../../../icons';

export const AppDisclosure: FC<{
  children?: React.ReactNode | React.ReactNode[];
  title: React.ReactNode;
  defaultExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}> = ({
  className,
  title,
  children,
  defaultExpanded = false,
  setIsExpanded = () => {},
}) => {
  return (
    // https://headlessui.dev/react/disclosure
    // NOTE: pas encore possible de controller ce composant: https://github.com/tailwindlabs/headlessui/discussions/362
    <Disclosure defaultOpen={defaultExpanded}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`focus:outline-none ${className ?? ''}`}
            onClick={() => setIsExpanded(open)}
          >
            {title}
            {open ? (
              <AppHeroIcons.actionReduce className="w-4 h-4 text-white" />
            ) : (
              <AppHeroIcons.actionExtend className="w-4 h-4 text-white" />
            )}
          </Disclosure.Button>

          {/*
            Use the Transition + open render prop argument to add transitions.
          */}
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            {/*
              Don't forget to add `static` to your Disclosure.Panel!
            */}
            <Disclosure.Panel static>{children}</Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
