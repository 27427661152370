import React, { useEffect } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppPageContainerWithFixedBars } from '../../../_core/modules/layout';
import { AppSignaturePad } from '../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab5Inquiries/AppSignaturePad';
import { DiveCenterBookingInquiryPage_FormHeader } from './DiveCenterBookingInquiryPage_FormHeader';
import { DiveCenterBookingInquiryPage_FormSection } from './DiveCenterBookingInquiryPage_FormSection';
import {
  DiveCenterBookingInquiryPageLocalState,
  useDiveCenterBookingInquiryPageLocalState,
} from './useDiveCenterBookingInquiryPageLocalState.hook';

export const DiveCenterBookingInquiryPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
    bookingId: string;
  };
}) => {
  const query = new URLSearchParams(window.location.search);
  const inquiryId = query.get('inquiryId');
  const bookingMemberId = query.get('bookingMemberId');

  const securityUser = useAppSecurityUser();

  const bookingId = navigationContext?.bookingId;
  const diverId = securityUser.diver?.diverId;

  const localState: DiveCenterBookingInquiryPageLocalState =
    useDiveCenterBookingInquiryPageLocalState({
      bookingId,
      diverId,
      inquiryId,
      bookingMemberId,
    });

  const {
    state: { form, viewState, clubResume },
    data: { loadableContent, aggregatedData, aggregatedBookingResume, inquiry },
  } = localState;

  const clubPublicSettings = clubResume?.clubSettings?.publicSettings;

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      if (!aggregatedBookingResume) {
        appLogger.warn(
          '[DiveCenterBookingCheckoutPage] bookingResume not found, redirect back',
          aggregatedData,
        );
        // TODO redirectTo(backUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.contentState, loadableContent.lastActionStatus]);

  return aggregatedBookingResume ? (
    <AppPageContainerWithFixedBars
      contentState={loadableContent.contentState}
      lastActionStatus={loadableContent.lastActionStatus}
      marginBottom={true}
      paddingBottom={true}
      contentClassName="bg-gray-50 app-p-content"
    >
      {viewState.mode === 'inquiry-resume' && inquiry && (
        <div className="grid gap-4">
          <DiveCenterBookingInquiryPage_FormHeader
            className="w-full"
            inquiry={inquiry}
            localState={localState}
            form={form}
          />
          <div className="grid gap-8">
            {inquiry.sections.map((section, sectionIndex) => (
              <DiveCenterBookingInquiryPage_FormSection
                key={sectionIndex}
                section={section}
                sectionIndex={sectionIndex}
                form={form}
                inquiry={inquiry}
                localState={localState}
              />
            ))}
          </div>
          {clubPublicSettings?.customerSpace?.consents?.padSignature
            ?.enabled && <AppSignaturePad />}
        </div>
      )}
    </AppPageContainerWithFixedBars>
  ) : null;
};
