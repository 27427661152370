"use strict";
exports.__esModule = true;
exports.APP_COMPANY_MESSAGE_PARAMS_ALL = void 0;
exports.APP_COMPANY_MESSAGE_PARAMS_ALL = [
    'CENTRE_NOM',
    'CENTRE_LIEU',
    'CENTRE_TELEPHONE',
    'PARTICIPANT_PRENOM',
    'ESPACE_CLIENT_LIEN_RESA',
    'SESSION_DATE',
    'SESSION_HEURE',
];
