"use strict";
exports.__esModule = true;
exports.DEFAULT_PROFILE_ACCOUNTANT = void 0;
// comptable
exports.DEFAULT_PROFILE_ACCOUNTANT = {
    label: 'Stats & comptabilité',
    authorizations: {
        // NOUVELLE HIERARCHIE: inspirée de anim-live AuthUserCompanyAuthorizations
        staff: {
            view: {},
            action: {}
        },
        creditNote: {
            // avoirs
            view: {
                bookingDetails: true,
                paymentsList: true
            },
            action: {
                create: false,
                edit: false,
                "delete": false
            }
        },
        communication: {
            action: {
                sendSessionMessage: true,
                sendSessionMessageEmail: true,
                sendSessionMessageSms: true,
                sendSessionMessageWhatsApp: true,
                sendBookingMessage: true,
                sendBookingMessageEmail: true,
                sendBookingMessageSms: true,
                sendBookingMessageWhatsApp: true
            }
        },
        // DEPRECATED: ancienne hiérarchie
        view: {
            planning: {
                participant: false,
                event: false
            },
            participant: {
                diver: true,
                history: true,
                booking: true,
                billing: true,
                payment: true
            },
            dashboard: {
                dailyCash: true,
                dailyPlanningResume: true,
                dailyStats: true,
                stats: true
            },
            lists: {
                diver: true,
                participant: true,
                billing: true,
                payment: true
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                mabadiveBilling: true
            }
        },
        edit: {
            participant: {
                diver: false,
                booking: false,
                billing: false,
                billingCreate: false,
                billingEdit: false,
                billingDelete: false,
                payment: false,
                paymentCreate: false,
                paymentDelete: false,
                paymentEdit: false,
                paymentOnlineCapture: false
            },
            planning: {
                session: false,
                event: false,
                securitySheet: false
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                accountProfiles: false,
                general: false,
                onlinePayment: false,
                accountProfilesImpersonate: false,
                archiveData: false,
                accomodation: false,
                equipment: false,
                customerSpace: false,
                exportDaily: false,
                inquiry: false
            }
        },
        download: {
            dailyCash: true,
            securitySheet: false,
            dailyResume: false,
            bookings: true,
            divers: true
        },
        multiDiveCenters: {
            switchCenter: false,
            view: {
                planning: false,
                participant: false,
                dashboard: false,
                lists: false,
                settings: false
            },
            edit: {
                participant: false,
                planning: false,
                settings: false
            }
        }
    }
};
