/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { AppButton, AppIconsUI } from 'src/business/_core/modules/layout';
import { DiveCenterBookingMemberEditPanelLocalState } from './useDiveCenterBookingMemberEditPanelLocalState';

export const BookingCheckoutEditMembersPanelNextOrFinishButton: FC<{
  localState: DiveCenterBookingMemberEditPanelLocalState;
}> = ({ localState }) => {
  const { viewState, form, actions, editableParticipantsCount } = localState;

  return (
    <div className="flex gap-x-2 sm:gap-x-4 gap-y-2 flex-wrap">
      <AppButton
        type="button"
        className="uppercase font-bold"
        disabled={!form.formState.isValid}
        size="normal"
        icon={AppIconsUI.navRight}
        iconPosition="right"
        color={
          viewState.participantsIndex + 1 < editableParticipantsCount
            ? 'primary-outline-light'
            : 'primary-bg'
        }
        onClick={() => {
          actions.nextParticipant();
        }}
      >
        {viewState.participantsIndex + 1 < editableParticipantsCount ? (
          <>
            <span className="sm:hidden">{'Suiv'}</span>
            <span className="hidden sm:block">{'Suivant'}</span>
          </>
        ) : (
          'Terminer'
        )}
      </AppButton>
      {viewState.participantsIndex + 1 !== editableParticipantsCount &&
        editableParticipantsCount > 3 && (
          <AppButton
            type="button"
            className="uppercase font-bold"
            disabled={!form.formState.isValid}
            size="normal"
            icon={AppIconsUI.navRightFast}
            iconPosition="right"
            color={'danger-outline-light'}
            onClick={() => {
              actions.lastParticipant();
            }}
          >
            <div className="hidden sm:block">{'Dernier'}</div>
          </AppButton>
        )}
    </div>
  );
};
