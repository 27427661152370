import { AppInquiryForClubResumeGql_Customer } from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AppIconsInquiry } from '../../../../../_core/modules/layout';
import { diveCenterBookingUrlBuilder } from '../../../diveCenterBookingUrlBuilder.service';

export function DiveCenterBookingPageTab5Inquiries_InquiryBadge({
  inquiry,
  bookingId,
  bookingMemberId,
  clubPublicReference,
  status,
}: {
  inquiry: AppInquiryForClubResumeGql_Customer;
  bookingId: string;
  bookingMemberId: string;
  clubPublicReference: string;
  status: 'pending' | 'submitted' | 'ok';
  className?: string;
}) {
  const type = inquiry.type;
  const Icon = AppIconsInquiry[inquiry.type];

  const inquiryTypeLabel = useMemo(
    () => inquiryFormatter.formatInquiryType(inquiry.type),
    [inquiry.type],
  );

  const bookingCheckoutUrl = diveCenterBookingUrlBuilder.buildBookingInquiryUrl(
    {
      bookingId,
      clubPublicReference,
      bookingMemberId,
      inquiryId: inquiry._id,
    },
  );

  return (
    <Tippy delay={[200, 50]} placement="top" content={inquiryTypeLabel}>
      <Link
        to={bookingCheckoutUrl}
        className={clsx(
          'place-self-center inline-flex px-1 py-1 items-center gap-1 text-base font-bold uppercase rounded-lg',
          'border',
          status === 'ok'
            ? type === 'medical-form'
              ? 'border-green-600 text-green-600'
              : type === 'parental-consent'
              ? 'border-blue-600 text-blue-600'
              : 'border-gray-600 text-gray-600'
            : status === 'submitted'
            ? 'border-red-400 text-gray-400'
            : 'border-gray-400 text-gray-400',
        )}
      >
        {Icon && <Icon className="h-5 w-5" />}{' '}
        {inquiry.name && <span>{inquiry.name}</span>}
      </Link>
    </Tippy>
  );
}
