/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppLanguageCode } from '@mabadive/app-common-model';
import { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { DiveCenterBookingCheckoutPageLocalState } from '../../useDiveCenterBookingCheckoutPageLocalState.hook';

export type DiveCenterBookingMemberEditPanelLocalState = ReturnType<
  typeof useDiveCenterBookingMemberEditPanelLocalState
>;

export function useDiveCenterBookingMemberEditPanelLocalState({
  globalState,
}: {
  globalState: DiveCenterBookingCheckoutPageLocalState;
}) {
  const { form, formValue, viewState, setViewState, initialBookingResume } =
    globalState;

  const participantsCount = initialBookingResume?.participantsCount;
  const editableParticipantsCount =
    initialBookingResume?.editableParticipantsCount;
  const clubSettings = initialBookingResume?.clubResume?.clubSettings;
  const booking = initialBookingResume?.booking;

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
    reset,
  } = form;

  const baseFormName = `participants.${viewState.participantsIndex}` as const;

  const [hasCertification, certificationReference, expectedDiveMode] = useWatch(
    {
      control,
      name: [
        `${baseFormName}.bookingMember.details.hasCertification` as const,
        `${baseFormName}.diver.mainCertificationReference` as const,
        `${baseFormName}.bookingMember.details.expectedDive.diveMode` as const,
      ],
    },
  );

  const [
    languageCodeConfirmDialogAlreadyDisplayed,
    setLanguageCodeConfirmDialogAlreadyDisplayed,
  ] = useState(false);

  const onLanguageCodeUpdated = useCallback(
    (
      languageCode: AppLanguageCode,
      {
        participantIndex,
      }: {
        participantIndex: number;
      },
    ) => {
      if (languageCode && !languageCodeConfirmDialogAlreadyDisplayed) {
        const participants = getValues('participants');

        const otherParticipantsWithoutLanguageCount = participants.filter(
          (x, i) => !x?.diver?.languageCode && i !== participantIndex,
        ).length;

        if (otherParticipantsWithoutLanguageCount > 1) {
          // on ne le fait que si il y a au moins 2 autres plongeurs sans langue
          setLanguageCodeConfirmDialogAlreadyDisplayed(true);
          confirmDialog
            .confirm({
              title: 'Même langue pour les autres plongeurs?',
              message: `Souhaitez-vous définir la même langue pour les ${otherParticipantsWithoutLanguageCount} autres plongeurs ?`,
              type: 'noYesInfo',
            })
            .subscribe((confirmed) => {
              if (confirmed) {
                for (let i = 0; i < participants.length; i++) {
                  const participant = participants[i];
                  if (participant && !participant?.diver?.languageCode) {
                    setValue(
                      `participants.${i}.diver.languageCode`,
                      languageCode,
                    );
                  }
                }
              }
            });
        }
      }
    },
    [getValues, languageCodeConfirmDialogAlreadyDisplayed, setValue],
  );

  const insuranceEnabled = useMemo(() => {
    if (
      clubSettings.publicSettings?.customerSpace?.features?.insurance?.enabled
    ) {
      if (
        (hasCertification && certificationReference !== 'DEB') ||
        expectedDiveMode === 'supervised' ||
        expectedDiveMode === 'autonomous'
      ) {
        return true;
      }
    }
    return false;
  }, [
    certificationReference,
    clubSettings.publicSettings?.customerSpace?.features?.insurance?.enabled,
    expectedDiveMode,
    hasCertification,
  ]);

  const newsletterSettings =
    clubSettings.publicSettings?.customerSpace?.features?.newsletter;
  //   useDiveCenterBookingMemberEditNavigationSteps();

  return {
    ...globalState,
    actions: {
      ...globalState.actions,
      onLanguageCodeUpdated,
    },
    formState,
    participantsCount,
    editableParticipantsCount,
    booking,
    newsletterSettings,
    form,
    clubSettings,
    insuranceEnabled,
  };
}
