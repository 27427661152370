import { OnlineBookingCustomerConsent } from '@mabadive/app-common-model';
import React from 'react';
import { useRedirect } from '../../_core/data/hooks';
import { useAppRouter } from '../../_core/data/hooks/useAppRouter';
import { DiveCenterShoppingCartPageLocalState } from './useDiveCenterShoppingCartPageLocalState.hook';

export const DiveCenterShoppingCartSubmitPage_AcceptConsent = ({
  localState,
}: {
  localState: DiveCenterShoppingCartPageLocalState;
}) => {
  const redirectTo = useRedirect();

  const appRouter = useAppRouter();

  const { state, data, actions } = localState;
  const clubPublicReference = data.clubPublicReference;

  const clubSettings = data.clubSettings;
  const clubPublicSettings = data.clubPublicSettings;
  const diveCenter = data.diveCenter;

  const appUiTheme = clubPublicSettings?.appUiTheme;

  const consentsToAccept: OnlineBookingCustomerConsent[] =
    state?.consentsToAccept;

  console.log('xxx consentsToAccept:', consentsToAccept);

  return (
    <div className="h-full w-full flex flex-col gap-16 justify-between">
      <div className="w-full max-w-3xl mx-auto  flex flex-col justify-between gap-4 md:gap-8">
        <div className="w-full max-w-6xl mx-auto flex flex-col gap-4">
          <h2
            className="text-lg font-bold leading-4 uppercase"
            style={{
              color: data.appUiTheme.title1.textColor,
            }}
          >
            Veuillez accepter {consentsToAccept?.length} consentements.
          </h2>
        </div>
      </div>
    </div>
  );
};
