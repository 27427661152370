/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Booking,
  BookingCustomerPayment,
  BookingMember,
  BookingProduct,
  BookingSession,
  BookingSessionParticipant,
  ClubDiver,
  ClubParticipant,
  ClubResumeGql_Customer,
  ClubResumePublicDiveCenter,
  Customer_ClubResumePublic,
  DiveSession,
} from '@mabadive/app-common-model';
import { useEffect, useMemo } from 'react';
import {
  LoadableContent,
  LoadableContentActions,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import {
  clubResumePublicCustomerBuilder,
  diverBookingRepository,
} from 'src/business/_core/data/store-repository';
import {
  DiverBookingCriteria,
  DiverBookingGraphqlResult,
  DiverBookingGraphqlResultBookinMember,
} from 'src/business/_core/data/store-repository/diver-booking/model';
import { diveCenterStore } from 'src/business/_core/store/diveCenterStore.service';
import {
  bookingResumeToBookingConvertor,
  diveSessionResumeToDiveSessionConvertor,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/services/02.update-state/entity-convertors';
import { DiveCenterBookingPageLoadedContent } from './model';
import { DiveCenterBookingPageDataCore } from './model/DiveCenterBookingPageDataCore.type';

export function useDiveCenterBookingPageLoadedContent({
  bookingId,
  diverId,
}: DiverBookingCriteria): LoadableContent<DiveCenterBookingPageLoadedContent> {
  const criteria = useMemo(
    () => ({
      bookingId,
      diverId,
      limitToDiverId: undefined, // TODO: si on limite, alors le contact n'est pas renvoyé, et ça fait tout péter
    }),
    [bookingId, diverId],
  );

  const loadableContent: LoadableContent<DiverBookingGraphqlResult> &
    LoadableContentActions = useLoadableContent(
    () =>
      diverBookingRepository.findOne(criteria, {
        isSubscription: true,
      }),
    [criteria],
    {
      debugName: 'useDiveCenterBookingPageLoadedContent',
      useSnapshot: false,
    },
  );

  const clubResume: Customer_ClubResumePublic = useMemo(() => {
    const clubResumeGql: ClubResumeGql_Customer =
      loadableContent?.content?.clubResume;
    if (clubResumeGql) {
      const clubResume: Customer_ClubResumePublic =
        clubResumePublicCustomerBuilder.buildFromGql_Customer(clubResumeGql);
      return clubResume;
    }
  }, [loadableContent?.content]);

  useEffect(() => {
    if (clubResume) {
      diveCenterStore.clubResume.set(clubResume);
    }
  });

  const content: DiveCenterBookingPageLoadedContent = useMemo(
    () => buildContent(loadableContent, { clubResume }),
    [clubResume, loadableContent],
  );

  return {
    contentState: loadableContent?.contentState,
    lastActionStatus: loadableContent?.lastActionStatus,
    startDate: loadableContent?.startDate,
    endDate: loadableContent?.endDate,
    durationInMs: loadableContent?.durationInMs,
    content,
  };
}
function buildContent(
  loadableContent: LoadableContent<DiverBookingGraphqlResult> &
    LoadableContentActions,
  { clubResume }: { clubResume: Customer_ClubResumePublic },
): DiveCenterBookingPageLoadedContent {
  const diverBookingGraphqlResult: DiverBookingGraphqlResult =
    loadableContent?.content;

  const clubResumes: Customer_ClubResumePublic[] = [];
  const clubDivers: ClubDiver[] = [];
  const bookings: Booking[] = [];
  const bookingSessions: BookingSession[] = [];
  const bookingProducts: BookingProduct[] = [];
  const bookingMembers: BookingMember[] = [];
  let bookingPayments: BookingCustomerPayment[] = [];
  const diveSessions: DiveSession[] = [];
  const bookingSessionParticipants: BookingSessionParticipant[] = [];
  const clubParticipants: ClubParticipant[] = [];
  let clubReference: string;
  let diveCenter: ClubResumePublicDiveCenter;

  const initialBookingResume = diverBookingGraphqlResult;
  if (initialBookingResume) {
    clubReference = initialBookingResume.clubReference;
    diveCenter = initialBookingResume.diveCenter;

    const booking: Booking = bookingResumeToBookingConvertor.convert(
      initialBookingResume,
      { clubReference },
    );
    bookings.push(booking);

    if (
      !clubResumes.find((x) => x.club.reference === clubResume.club.reference)
    ) {
      clubResumes.push(clubResume);
    }

    initialBookingResume.bookingSessions.forEach((bookingResumeSession) => {
      const { diveSession: dsr } = bookingResumeSession;
      bookingSessions.push({
        ...bookingResumeSession,
        clubReference,
        bookingId: initialBookingResume._id,
        diveCenterId: bookingResumeSession.diveSession.diveCenterId,
      });
      if (
        diveSessions.find((ds) => ds.reference === dsr.reference) === undefined
      ) {
        const diveSession: DiveSession =
          diveSessionResumeToDiveSessionConvertor.convert(dsr, {
            clubReference,
          });
        diveSessions.push(diveSession);
      }
    });
    initialBookingResume.bookingProducts.forEach((bp) => {
      bookingProducts.push(bp);
      bookingSessionParticipants.push(bp.bookingSessionParticipant);
      clubParticipants.push(
        bp.bookingSessionParticipant.diveSessionParticipant,
      );

      if (bookingProducts.find((ds) => ds._id === bp._id) === undefined) {
        bookingProducts.push(bp);
      }
    });

    bookingPayments = bookingPayments.concat(
      initialBookingResume?.bookingPayments,
    );
    initialBookingResume.bookingMembers.forEach(
      (bookingResumeMember: DiverBookingGraphqlResultBookinMember) => {
        const { diver: diverWithPayments, ...bookingMember } =
          bookingResumeMember;
        const { payments, ...diver } = diverWithPayments;
        bookingMembers.push({
          ...bookingMember,
        });

        if (clubDivers.find((ds) => ds._id === diver._id) === undefined) {
          clubDivers.push(diver);
        }
        for (const payment of payments) {
          if (
            bookingPayments.find((x) => x._id === payment._id) === undefined
          ) {
            bookingPayments.push(payment);
          }
        }
      },
    );
  }

  const entities: DiveCenterBookingPageDataCore = {
    clubResumes,
    clubDivers,
    bookings,
    bookingSessions,
    bookingProducts,
    bookingMembers,
    diveSessions,
    bookingSessionParticipants,
    clubParticipants,
    bookingCustomerUpdates: initialBookingResume?.bookingCustomerUpdates ?? [],
    clubReference,
    diveCenter,
    clubPublicReference: clubResume?.clubSettings?.clubPublicReference,
    bookingPayments,
  };
  const content: DiveCenterBookingPageLoadedContent = {
    diverBookingGraphqlResult,
    entities,
  };
  return content;
}
