import {
  AppInquiryForClubResumeGql_Customer,
  ClubCommerceBookingDepositGql_Company,
  ClubCommercePurchasePaymentGql_Customer,
  DiveSessionResumeGql_Customer,
} from '@mabadive/app-common-model';
import { diveCenterGraphqlClubAttributesBuilder } from '../../../../dive-center/diveCenterGraphqlClubAttributesBuilder.service';
import { bookingMemberResumeAttributesBuilder } from './bookingMemberResumeAttributesBuilder.service';
import { bookingProductResumeAttributesBuilder } from './bookingProductResumeAttributesBuilder.service';

export const bookingResumeAttributesBuilder = {
  buildAttributes,
};
/**
 * Build graphql attributes for BookingResume
 */
function buildAttributes({
  bookingProducts,
  limitToDiverId,
}: {
  bookingProducts: 'only-active' | 'all';
  limitToDiverId?: string;
}): string {
  const bookingProductsFilters: string[] = [];
  if (bookingProducts === 'only-active') {
    bookingProductsFilters.push('bookingProductStatus: {_neq: "cancelled"}');
    bookingProductsFilters.push('purchaseStatus: {_eq: "pending"}');
  }

  const bookingMembersWhereClause = limitToDiverId
    ? `(where: {diverId: {_eq: "${limitToDiverId}"}})`
    : '';

  return `
  _id
  diveCenterId
  clubReference
  bookingDate
  bookingLastUpdateDate
  bookingStatus
  active
  bookingContactDiverId
  bookingJourney
  bookingInitialPlan
  bookingCustomerConfig
  bookingGroup
  clubResume: club {
    ${diveCenterGraphqlClubAttributesBuilder.buildAttributes()}
    boats {
      _id
      name
      color
    }
    diveSites {
      _id
      ref
      name
      emojiId
    }
    inquiries {
      ${AppInquiryForClubResumeGql_Customer}
    }
  }
  diveCenter {
    _id
    publicData
    publicSettings
  }
  bookingContact {
    _id
    lastName
    firstName
    fakeName
    phoneNumber
    emailAddress
    birthdate
    mainCertificationReference
    comment
    divingComment
    bookingDeposits
  }
  bookingMembers ${bookingMembersWhereClause} {
    ${bookingMemberResumeAttributesBuilder.buildAttributes()}
  }
  bookingSessions {
    _id
    diveCenterId
    bookingId
    diveSessionReference
    bookingSessionStatus
    diveSession {
      ${DiveSessionResumeGql_Customer}
    }
  }
  bookingProducts${
    bookingProductsFilters.length
      ? `(where: {${bookingProductsFilters.join(',')}})`
      : ''
  } {
    ${bookingProductResumeAttributesBuilder.buildAttributes()}
  }
  bookingCustomerUpdates(where: {status: {_in: ["draft","to-validate","ko"]}}, order_by: {_createdAt: asc}) {
    _id
    clubReference
    bookingId
    authorDiverId
    status
    submitDate
    reviewDate
    payload
  }
  bookingDeposits {
    ${ClubCommerceBookingDepositGql_Company}
  }
  bookingPayments {
    ${ClubCommercePurchasePaymentGql_Customer}
  }
`;
}
