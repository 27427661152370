import React, { useRef } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';

export const AppSignaturePad = ({ className }: { className?: string }) => {
  const signaturePadRef = useRef<SignaturePad>(null);

  const saveSignature = async () => {
    if (signaturePadRef.current) {
      const dataUrl = signaturePadRef.current.toDataURL();

      try {
        const response = await fetch(dataUrl);
        const blob = await response.blob();
        const formData = new FormData();
        formData.append('signature', blob, 'signature.png');

        // Replace 'YOUR_SERVER_ENDPOINT' with your server endpoint
        const serverResponse = await fetch('YOUR_SERVER_ENDPOINT', {
          method: 'POST',
          body: formData,
        });

        const result = await serverResponse.json();
        console.log('Success:', result);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  const clearSignature = async () => {
    if (signaturePadRef.current) {
      try {
        signaturePadRef.current.clear();
      } catch (error) {
        // error
      }
    }
  };

  return (
    <div className={className}>
      <h2>Signature électronique</h2>
      <div className="border max-h-[200px] max-w-[300px]">
        <SignaturePad
          // width={300}
          // height={300}
          ref={signaturePadRef}
          options={{ penColor: 'blue', backgroundColor: '#fafafa' }}
          redrawOnResize={true}
        />
      </div>
      <div className="flex gap-8">
        <button onClick={saveSignature}>Valider</button>
        <button onClick={clearSignature}>Effacer</button>
      </div>
    </div>
  );
};
