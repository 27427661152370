import {
  ClubProductPackageOfferView,
  ClubPublicSettings,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../../data/sorter/dataSorter.service';
import { productPackageFormatter } from '../../dive/productPackageFormatter.service';
import { ClubProductPackageOfferViewSortByAttribute } from './ClubProductPackageOfferViewSortByAttribute.type';
import { clubProductPackageOfferViewSortAttributesBuilder } from './clubProductPackageOfferViewSortAttributesBuilder.service';

export const clubProductPackageOfferViewSorter = {
  sortOffersBy,
  sortOffersBy_withTransform,
};

function sortOffersBy<T extends ClubProductPackageOfferView>(
  offers: T[],
  {
    attributeName,
    publicSettings,
    asc,
  }: {
    attributeName: ClubProductPackageOfferViewSortByAttribute;
    publicSettings: ClubPublicSettings;
    asc?: boolean;
  },
): T[] {
  if (asc === undefined) {
    asc = true;
  }

  const type = isFullText(attributeName) ? 'full-text' : 'default';

  return dataSorter.sortMultiple(offers, {
    getSortAttributes: (x: T) => {
      const defaultSortAttributes =
        clubProductPackageOfferViewSortAttributesBuilder.getSortAttributes(x, {
          mode: attributeName === 'training' ? 'training' : 'dive',
        });

      return [
        {
          value: getAttribute(x, { attributeName, publicSettings }),
          type,
        },
        ...defaultSortAttributes,
      ];
    },
    asc,
  });
}
function sortOffersBy_withTransform<T extends ClubProductPackageOfferView, O>(
  offers: O[],
  {
    transform,
    attributeName,
    publicSettings,
    asc,
  }: {
    transform: (o: O) => T;
    attributeName: ClubProductPackageOfferViewSortByAttribute;
    publicSettings: ClubPublicSettings;
    asc?: boolean;
  },
): O[] {
  if (asc === undefined) {
    asc = true;
  }

  const type = isFullText(attributeName) ? 'full-text' : 'default';

  return dataSorter.sortMultiple(offers, {
    getSortAttributes: (x: O) => {
      const defaultSortAttributes =
        clubProductPackageOfferViewSortAttributesBuilder.getSortAttributes(
          transform(x),
          {
            mode: attributeName === 'training' ? 'training' : 'dive',
          },
        );

      return [
        {
          value: getAttribute(x, { attributeName, publicSettings }),
          type,
        },
        ...defaultSortAttributes,
      ];
    },
    asc,
  });
}

function isFullText(attributeName) {
  const textAttributes: ClubProductPackageOfferViewSortByAttribute[] = [
    'name',
    'nameShort',
  ];
  return textAttributes.includes(attributeName);
}

function getAttribute<T extends ClubProductPackageOfferView>(
  x: Partial<T>,
  {
    attributeName,
    publicSettings,
  }: {
    attributeName: ClubProductPackageOfferViewSortByAttribute;
    publicSettings: ClubPublicSettings;
  },
) {
  if (attributeName === 'dive' || attributeName === 'training') {
    return 0; // default sort is OK
  }
  if (attributeName === 'name' || attributeName === 'nameShort') {
    const nameDetails = productPackageFormatter.formatNameDetails(
      x?.productPackage,
      {
        publicSettings,
      },
    );
    return `${nameDetails.prefix} ${nameDetails.name} ${
      nameDetails.label ?? ''
    } ${nameDetails.suffix ?? ''}`;
  }

  return (x as any)[attributeName] as any;
}
