/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppLanguageCode,
  Booking,
  Customer_ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppInputRHF, AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { useLanguagesOptions } from 'src/pages';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { DiveCenterBookingMemberEditFormModel } from '../model';
import { DiveCenterBookingMemberEditFormAddress } from './DiveCenterBookingMemberEditFormAddress';

export const DiveCenterBookingMemberEditFormContact = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  onLanguageCodeUpdated,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  onLanguageCodeUpdated: (
    languageCode: AppLanguageCode,
    {
      participantIndex,
    }: {
      participantIndex: number;
    },
  ) => void;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const baseFormName = `participants.${participantsIndex}` as const;

  const [birthdate] = useWatch({
    control,
    name: [`${baseFormName}.diver.birthdate` as const],
  });

  const autoFocus = useAutoFocus();

  const pendingList = booking.bookingCustomerConfig?.pendingList;

  const languagesOptions = useLanguagesOptions({
    scope: 'all',
  });

  return (
    <div className={className}>
      {pendingList?.diversContactInfo && (
        <div
          className={
            'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
          }
        >
          <AppFormControlRHF_Deprecated
            className={'md:col-span-2 w-full'}
            label="Prénom"
            control={control}
            required={false}
            name={`${baseFormName}.diver.firstName` as const}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="text"
                autoFocus={autoFocus}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'md:col-span-2 w-full'}
            label="Nom"
            control={control}
            name={`${baseFormName}.diver.lastName` as const}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'md:col-span-2 w-full flex gap-4'}
            label="Téléphone portable"
            control={control}
            name={`${baseFormName}.diver.phoneNumber` as const}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'md:col-span-2 w-full'}
            label="Adresse e-mail"
            control={control}
            validation={{
              email: true,
            }}
            name={`${baseFormName}.diver.emailAddress` as const}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="md:col-span-2 w-full"
            label="Date de naissance"
            control={control}
            name={`${baseFormName}.diver.birthdate` as const}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF
                initialView={birthdate ? 'weeks' : 'years'}
                maxYear={new Date().getFullYear()}
                {...props}
                className="w-full"
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'md:col-span-2 w-full'}
            label="Langue"
            control={control}
            name={`${baseFormName}.diver.languageCode` as const}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={languagesOptions}
                fullWidth
                onChange={(languageCode) => {
                  if (languageCode) {
                    onLanguageCodeUpdated(languageCode, {
                      participantIndex: participantsIndex,
                    });
                  }
                }}
              />
            )}
          />
        </div>
      )}
      {pendingList?.diversContactInfo && (
        <div className={'mt-2 w-full max-w-[97vw] flex flex-col'}>
          <AppFormControlRHF_Deprecated
            className={'md:col-span-4 w-full'}
            label="Personne à contacter en cas d'urgence (Nom + tél)"
            control={control}
            name={`${baseFormName}.diver.emergencyContact` as const}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="text"
                multiline
                rowsMax={15}
                rows={2}
              />
            )}
          />
        </div>
      )}
      {pendingList?.diversPostalAddressInfo && (
        <DiveCenterBookingMemberEditFormAddress
          key={participantsIndex}
          className="mt-2"
          form={form}
          participantsIndex={participantsIndex}
          clubSettings={undefined}
        />
      )}
    </div>
  );
};
